<template>
  <div>
    <div>
      <img class="titlebg" src="../assets/title.png" />
    </div>
    <div id="container">

    </div>
    <div class="datetimebox">
      <span @click="changetype(1)" :class="type == 1 ? 'this' : ''">统计模式</span>
      <span @click="changetype(2)" :class="type == 2 ? 'this' : ''">地图模式</span>
    </div>

    <div :class="['datetimebox', 'tuichu', type == 1 ? '' : 'xianse']">
      <span @click="tongjifanhuicj" v-if="tongji_lookjxs">返回</span>
      <span @click="loginoutbtn">退出</span>

    </div>

    <div class="shebeigpsinfo" :class="showcusgpsdata?'':'hide'">
      <span class="closegps" @click="showcusgpsdata = false;">X</span>
      <ul>
        <li>
          <span>客户名称：</span>{{cusgpsdata.cusname}} 
        </li>
        <li>
          <span>代理名称：</span>{{cusgpsdata.dailiname}}
        </li>
        <li>
          <span>注塑机编码：</span>{{cusgpsdata.shebeibm}}
        </li>
        <li>
          <span>定位器IMEI：</span>{{cusgpsdata.dingweimkimei}}
        </li>
        <li>
          <span>当前位置：</span>{{cusgpsdata.dangqianwz}}
        </li>
      </ul>

    </div>

    <div v-if="tongji_lookjxs" class="nowjxsdata">当前数据：{{selectjxs}}</div>

    <el-row v-if="type == 2" :gutter="20" class="mainbox">
      
      <!-- 以下是客户模式要展示的信息 -->
      <el-col :span="6" v-if="map_lookjxsdetail" :class="[showpanel ? 'showpanel' : '', 'leftpanel']">
        <div class="mianban " :style="{ 'height': 230 + 'px' }">
          <span class="closepanel" @click="closepanel">X</span>
          <div class="box-title">
            设备运行概括
          </div>
          <!-- 设备总量 -->
          <div class="box-body">
            <div>
              <div class="box-body-label">设备总量</div>
              <div class="box-body-value">
                <div clas="">
                  <span>{{ homedata.shebeiinfo.all }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">正常运行</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.run == 0 ? '0.3rem' : (homedata.shebeiinfo.run / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.run }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>故</span>障</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.guzhang == 0 ? '0.3rem' : (homedata.shebeiinfo.guzhang / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.guzhang }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>离</span>线</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.lixian == 0 ? '0.3rem' : (homedata.shebeiinfo.lixian / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.lixian }}台</span>
                </div>
              </div>
            </div>
          </div>

          
        </div>

        <div class="mianban zhongjian" :style="{ 'height': 180 + 'px' }">
          <div class="box-title">
            设备分配概括
          </div>
          <!-- 设备总量 -->
          <div class="box-body">
            <div>
              <div class="box-body-label">设备总数量</div>
              <div class="box-body-value">
                <div clas="">
                  <span>{{ homedata.shebeifpfb.allcount }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">已分配数量</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeifpfb.yifenp == 0 ? '0.3rem' : (homedata.shebeiinfo.yifenp / homedata.shebeiinfo.allcount * 100) + '%') }">
                  <span>{{ homedata.shebeifpfb.yifenp }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">未分配数量</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeifpfb.weifp == 0 ? '0.3rem' : (homedata.shebeifpfb.weifp / homedata.shebeifpfb.allcount * 100) + '%') }">
                  <span>{{ homedata.shebeifpfb.weifp }}台</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="mianban zhongjian" :style="{ 'height': 300 + 'px' }">

          <div class="box-title" style="margin-bottom: 0.2rem;">
            {{ map_selectjxs }} 客户概括
          </div>
          <div class="box-body " style="padding-top: 0px;">
            <ul>
              <li class="agentlisttitle head">
                <span>客户名称</span>
                <span>设备总数</span>
                <span>正常运行</span>
                <span>停机</span>
              </li>
            </ul>
            <vue-seamless-scroll :key="timeKey" :data="agentlist" :class-option="scrollOption" class="info">
              <ul>
                <li class="agentlisttitle agentlistbody" v-for="(item, index) in map_kehulist">
                  <span>{{ item.name }}</span>
                  <span>{{ item.allcount }}</span>
                  <span>{{ item.yifenp }}</span>
                  <span>{{ item.weifenp }}</span>
                </li>


              </ul>
            </vue-seamless-scroll>
          </div>
        </div>

      </el-col>

      <el-col :span="6" v-if="map_lookjxs" :class="[showpanel ? 'showpanel' : '', 'leftpanel']">

        <div class="mianban zhongjian" style="height: calc(100vh - 180px );">
          <span class="closepanel" @click="closepanel">X</span>
          <div class="box-title" style="margin-bottom: 0.2rem;">
            {{ map_shengfen }}经销商概括
          </div>
          <div class="box-body " style="padding-top: 0px;">
            <ul>
              <li class="agentlisttitle head">
                <span>经销商名称</span>
                <span>设备总数</span>
                <span>已分配</span>
                <span>未分配</span>
              </li>
            </ul>
            <ul>
              <li @click="changejxs(item.name)" class="agentlisttitle agentlistbody"
                v-for="(item, index) in map_agentlist.filter(r => r.shengfen == map_shengfen)">
                <span>{{ item.name }}</span>
                <span>{{ item.allcount }}</span>
                <span>{{ item.yifenp }}</span>
                <span>{{ item.weifenp }}</span>
              </li>
            </ul>

          </div>
        </div>

      </el-col>
    </el-row>
    <el-row v-else
      style="width: 100%;height: 100vh;background-color: #1f2e3d; color: #fff;position: absolute;z-index: 11;">

      <div class="shujumoshi" v-if="tongji_lookcj">
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="经销商">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%">经销商名称</th>
                      <th style="width: 110px;">进货量(台)</th>
                      <th style="width: 110px;">出货量(台)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr @click="lookjsxtongjidata(item)" v-for="(item, index) in alljingxiaoslist">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="客户">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata cusdata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">客户名称</th>
                      <th style="width: 110px;">总装机量(台)</th>
                      <th style="width: 110px;">本月购买量(台)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in allkehulist">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="设备产销情况">
              <div class="shujumoshicontent">
                <div class="chanxiaoinfo">
                  <div>
                    <div>累计生产(台)</div>
                    <div>1560</div>
                  </div>
                  <div>
                    <div>累计销售(台)</div>
                    <div>1460</div>
                  </div>
                  <div>
                    <div>本月生产(台)</div>
                    <div>79</div>
                  </div>
                  <div>
                    <div>本月销售(台)</div>
                    <div>70</div>
                  </div>
                </div>
              </div>
              <div style="text-align: center;  ">
                <div id="nianduchanxiao" style="width: 100%;height: 270px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="年度产销走势图">
              <div class="shujumoshicontent">
                <div id="chanxiaozst" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="累计单品销售">
              <div class="shujumoshicontent">
                <div id="leijidpxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="销售前五经销商">
              <div class="shujumoshicontent">
                <div id="xiaoshouqwjxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
      </div>

      <div class="shujumoshi" v-if="tongji_lookjxs">
        <div>
          <div  >
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="售出设备运行情况">
              <div class="shujumoshicontent">
                <div id="shouchusbyxqkbyjxs" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="客户">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata cusdata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">客户名称</th>
                      <th style="width: 110px;">总装机量(台)</th>
                      <th style="width: 110px;">本月购买量(台)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in allkehulistbyjxs">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="设备销售情况">
              <div class="shujumoshicontent">
                <div class="chanxiaoinfo">
                  <div>
                    <div>累计购买(台)</div>
                    <div>320</div>
                  </div>
                  <div>
                    <div>累计销售(台)</div>
                    <div>315</div>
                  </div>
                  <div>
                    <div>本月购买(台)</div>
                    <div>17</div>
                  </div>
                  <div>
                    <div>本月销售(台)</div>
                    <div>13</div>
                  </div>
                </div>
              </div>
              <div style="text-align: center;  ">
                <div id="nianduchanxiaobyjxs" style="width: 100%;height: 300px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="年度销售走势图">
              <div class="shujumoshicontent">
                <div id="chanxiaozstbyjxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="累计单品销售">
              <div class="shujumoshicontent">
                <div id="leijidpxsbyjxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="累计销售前五客户">
              <div class="shujumoshicontent">
                <div id="xiaoshouqwjxsbyjxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
      </div>

    </el-row>

    <nav :class="['dd-nav', shownav ? 'show' : '']" @mouseleave="mouseleave" @mouseenter="mouseenter">
      <ul>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="生产管理" placement="left">
            <a>
              <span @click="selectmodel('producesys')" class="icon iconfont">&#xe62f;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="设备管理" placement="left">
            <a>
              <span @click="selectmodel('shebeigl')" class="icon iconfont">&#xe792;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="BOM管理" placement="left">
            <a>
              <span @click="selectmodel('bomsys')" class="icon iconfont">&#xe631;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="品质管理" placement="left">
            <a>
              <span @click="selectmodel('qualitysys')" class="icon iconfont">&#xe61c;</span>

            </a>
          </el-tooltip>
        </li>

        <li>
          <el-tooltip class="item" effect="dark" content="系统日志" placement="left">
            <a>
              <span @click="selectmodel('syslog')" class="icon iconfont">&#xe66a;</span>

            </a>
          </el-tooltip>
        </li>
        <li>
          <el-tooltip class="item" effect="dark" content="系统管理" placement="left">
            <a>
              <span @click="selectmodel('usersys')" class="icon iconfont">&#xe69b;</span>

            </a>
          </el-tooltip>
        </li>
      </ul>
    </nav>

    <div class="dd-mask" v-if="showddmask">
      <div>

        切换数据中，请稍后...
      </div>
    </div>

  </div>
</template>
<script>

import { gethomeinfo } from '../api/api';
import * as echarts from 'echarts';

export default {
  data() {
    return {
      mainbanheight: 0,
      value: new Date(),
      nowdate: 0,
      shownav: false,
      showtimer: null,
      nianyue: '',
      nowtime: '',
      nowweek: '',
      timexdata: [],
      homedata: {
        shebeiinfo: {},
        shebeifpfb: {}
      },
      timer: null,
      userinfo: null,
      map: null,
      timeKey: '1234',
      agentlist: [],
      type: 1,   //1 图表模式， 2 地图模式

      //以下是统计模式
      alljingxiaoslist: [
        ['宁波海天经销商', 120, 113],
        ['苏州海天经销商', 110, 101],
        ['徐州海天经销商', 92, 90],
        ['芜湖海天经销商', 73, 70],
        ['信阳海天经销商', 70, 70],
        ['常德海天经销商', 66, 65],
        ['烟台海天经销商', 63, 63],
      ],
      allkehulist: [
        ['江苏启东塑料制品厂', 37, 21],
        ['东莞市世邦塑料制品厂', 35, 19],
        ['芜湖市协宏塑胶制品厂', 32, 19],
        ['中谷精密塑料制品公司', 29, 17],
        ['上海杜鹃塑料制品厂', 29, 15],
        ['世宏塑料制品(无锡)有限公司', 28, 15],
        ['惠州市塑料制品厂', 28, 14],
      ],
      showpanel: false,  //地图模式下展示 plan信息
      map_lookjxs: true,
      map_lookkh: false,
      map_lookjxsdetail: false,
      map_shengfen: null,
      map_agentlist: [{
        name: '合肥海天经销商',
        allcount: 120,
        yifenp: 109,
        weifenp: 11,
        shengfen: '安徽省'
      }, {
        name: '蚌埠海天经销商',
        allcount: 110,
        yifenp: 101,
        weifenp: 9,
        shengfen: '安徽省'
      }, {
        name: '安庆海天经销商',
        allcount: 92,
        yifenp: 90,
        weifenp: 2,
        shengfen: '安徽省'
      }, {
        name: '芜湖海天经销商',
        allcount: 73,
        yifenp: 70,
        weifenp: 3,
        shengfen: '安徽省'
      }, {
        name: '滁州海天经销商',
        allcount: 120,
        yifenp: 109,
        weifenp: 11,
        shengfen: '安徽省'
      }, {
        name: '徐州海天经销商',
        allcount: 117,
        yifenp: 117,
        weifenp: 0,
        shengfen: '江苏省'
      }, {
        name: '苏州海天经销商',
        allcount: 95,
        yifenp: 89,
        weifenp: 6,
        shengfen: '江苏省'
      }, {
        name: '无锡海天经销商',
        allcount: 127,
        yifenp: 120,
        weifenp: 7,
        shengfen: '江苏省'
      }, {
        name: '盐城海天经销商',
        allcount: 117,
        yifenp: 109,
        weifenp: 8,
        shengfen: '江苏省'
      }, {
        name: '宿迁海天经销商',
        allcount: 97,
        yifenp: 93,
        weifenp: 4,
        shengfen: '江苏省'
      }, {
        name: '泗洪海天经销商',
        allcount: 136,
        yifenp: 130,
        weifenp: 6,
        shengfen: '江苏省'
      }, {
        name: '镇江海天经销商',
        allcount: 103,
        yifenp: 91,
        weifenp: 12,
        shengfen: '江苏省'
      }, {
        name: '深圳海天经销商',
        allcount: 97,
        yifenp: 93,
        weifenp: 4,
        shengfen: '广东省'
      }, {
        name: '广州海天经销商',
        allcount: 136,
        yifenp: 130,
        weifenp: 6,
        shengfen: '广东省'
      }, {
        name: '湛江海天经销商',
        allcount: 103,
        yifenp: 91,
        weifenp: 12,
        shengfen: '广东省'
      }],
      map_selectjxs: null,  //地图模式下选择的经销商
      map_kehulist: [{
        name: '世邦塑料制品厂',
        allcount: 35,
        yifenp: 33,
        weifenp: 2,
        shengfen: '安徽省'
      }, {
        name: '协宏塑胶制品厂',
        allcount: 29,
        yifenp: 22,
        weifenp: 7,
        shengfen: '安徽省'
      }],

      //以下是穿透到经销商的界面属性
      tongji_lookjxs: false, //是否能查看经销商界面
      tongji_lookcj: true,  //是否显示厂家信息
      allkehulistbyjxs: [
        ['江苏启东塑料制品厂', 37, 21],
        ['东莞市世邦塑料制品厂', 35, 19],
        ['芜湖市协宏塑胶制品厂', 32, 19],
        ['中谷精密塑料制品公司', 29, 17],
        ['上海杜鹃塑料制品厂', 29, 15],
        ['世宏塑料制品(无锡)有限公司', 28, 15],
        ['惠州市塑料制品厂', 28, 14],
      ],
      showddmask: false,   //全屏展示加载信息

      selectjxs:'', //档期按选中的经销商数据

      cusgpsdata:{},    //展示当前客户GPS数据
      showcusgpsdata:false, //是否展示当前客户GPS数据
    };
  },
  created() {
    let that = this;
    this.currentTime();

    this.userinfo = JSON.parse(sessionStorage.getItem("user"));

    let nowdate = (new Date);
    let today = nowdate.getDate();
    this.today = today;

    this.gethomedata();

    if (this.timer) {
      clearInterval(this.timer);
    }

    setTimeout(() => {
      that.initecharts();
    }, 2000)

  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {

  },
  methods: {
    /* 退出系统*/
    loginoutbtn() {
      let that = this;

      this.$confirm('确认要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '正在退出登录...',
          });
          setTimeout(() => {
            sessionStorage.clear();
            that.$router.push({ path: '/login' });
          }, 1000)

        })
        .catch(() => {

        })
    },
    initecharts() {
      let chanxiaozst = echarts.init(document.getElementById('chanxiaozst'));
      const optionchanxiaozst = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度销量', '月度产量'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度销量',
            min: 0,
            max: 200,
            interval: 40,
            axisLabel: {
              formatter: '{value} (台)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [

          {
            name: '月度销量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              101, 113, 96, 107, 99, 110, 112, 109, 107, 95, 104, 109
            ]
          },
          {
            name: '月度产量',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              98, 112, 110, 105, 90, 103, 115, 105, 100, 95, 110, 105
            ]
          }
        ]
      };

      chanxiaozst.setOption(optionchanxiaozst);

      let nianduchanxiao = echarts.init(document.getElementById('nianduchanxiao'));
      const optionnianduchanxiao = {
        color: ['red'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度产销比'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度产销比',
            min: 0,
            max: 100,

            axisLabel: {
              formatter: '{value} (%)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [


          {
            name: '月度产销比',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              88, 92, 97, 95, 90, 100, 85, 82, 93, 95, 94, 90
            ]
          }
        ]
      };

      nianduchanxiao.setOption(optionnianduchanxiao);

      let xiaoshouqwjxs = echarts.init(document.getElementById('xiaoshouqwjxs'));
      const optionxiaoshouqwjxs = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['常德海天经销', '宁波海天经销', '徐州海天经销', '无锡海天经销', '温州海天经销'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '累计销售前五经销商',
            type: 'bar',
            data: [219, 227, 253, 286, 302]
          }

        ],
        legend: {
          data: ['累计销售前五经销商'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      xiaoshouqwjxs.setOption(optionxiaoshouqwjxs);

      let leijidpxs = echarts.init(document.getElementById('leijidpxs'));
      const optionleijidpxs = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['VE III 400–8000', 'ZE 400–13600', 'JE 3600–33000', 'OE III 200–4000'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '累计单品销售',
            type: 'bar',
            data: [55, 120, 78, 66]
          }

        ],
        legend: {
          data: ['累计单品销售'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      leijidpxs.setOption(optionleijidpxs);
    },
    initechartsbyjxs() {
      let chanxiaozst = echarts.init(document.getElementById('chanxiaozstbyjxs'));
      const optionchanxiaozst = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度销量', '月度购买量'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度销量',
            min: 0,
            max: 50,

            axisLabel: {
              formatter: '{value} (台)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [

          {
            name: '月度销量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              13, 11, 9, 10, 15, 11, 8, 13, 10, 12, 9, 10
            ]
          },
          {
            name: '月度购买量',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              15, 17, 6, 11, 9, 13, 8, 9, 10, 9, 9, 15
            ]
          }
        ]
      };

      chanxiaozst.setOption(optionchanxiaozst);

      let nianduchanxiao = echarts.init(document.getElementById('nianduchanxiaobyjxs'));
      const optionnianduchanxiao = {
        color: ['red'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度购销比'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度购销比',
            min: 0,
            max: 100,

            axisLabel: {
              formatter: '{value} (%)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [


          {
            name: '月度购销比',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              88, 92, 97, 105, 90, 98, 85, 82, 93, 95, 94, 90
            ]
          }
        ]
      };

      nianduchanxiao.setOption(optionnianduchanxiao);

      let xiaoshouqwjxs = echarts.init(document.getElementById('xiaoshouqwjxsbyjxs'));
      const optionxiaoshouqwjxs = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['江苏启东塑料制品厂', '中谷精密塑料制品公司', '上海杜鹃塑料制品厂', '惠州市塑料制品厂', '沧州晨航塑料制品有限公司'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '累计销售前五客户',
            type: 'bar',
            data: [19, 21, 28, 33, 39]
          }

        ],
        legend: {
          data: ['累计销售前五客户'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      xiaoshouqwjxs.setOption(optionxiaoshouqwjxs);

      let leijidpxs = echarts.init(document.getElementById('leijidpxsbyjxs'));
      const optionleijidpxs = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['VE III 400–8000', 'ZE 400–13600', 'JE 3600–33000', 'OE III 200–4000'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '累计单品销售',
            type: 'bar',
            data: [13, 27, 18, 16]
          }

        ],
        legend: {
          data: ['累计单品销售'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      leijidpxs.setOption(optionleijidpxs);

      let shouchusbyxqk = echarts.init(document.getElementById('shouchusbyxqkbyjxs'));

      const optionshouchusbyxqk = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['离线', '故障', '正常运行', '售出设备总量'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '售出设备情况',
            type: 'bar',
            data: [35, 27, 253, 315]
          }

        ],
        legend: {
          data: ['售出设备情况'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      shouchusbyxqk.setOption(optionshouchusbyxqk);

    },
    gethomedata() {
      let that = this;
      gethomeinfo().then(res => {

        that.homedata = res.data;
        that.agentlist = res.data.agents;

        //   that.showyhfb();
        that.showarea();
      })
    },

    showyhfb() {

      let myChart = this.$echarts.init(document.getElementById("myChart"));

      let option = {
        tooltip: {
          trigger: "item"
        },
        color: [
          "#8256e9",
          "#eb6f49",
          "#00d98b",
          "#fdb626",
          "#00e4ed",
          "#5e97e6",
          "#43a923"
        ],
        legend: {
          textStyle: {
            color: "#fff"
          },
          type: "scroll",
          align: "left",
          orient: "vertical",
          right: "5%"
        },

        series: [
          {
            name: "用户分布",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },

            labelLine: {
              show: false
            },
            data: this.homedata.usertypelist
          }
        ]
      };

      // 绘制图表
      myChart.setOption(option);
    },
    mouseenter() {
      this.shownav = true;
      clearTimeout(this.showtimer);
    },
    mouseleave() {
      let that = this;
      this.showtimer = setTimeout(() => {
        that.shownav = false;
      }, 5000)
    },
    selectmodel(selectitem) {
      sessionStorage.setItem("selectmenu", selectitem);
      this.$router.push({ path: '/home' });

    },
    currentTime() {
      this.datetimer = setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.nianyue = `${year}年${month < 10 ? '0' + month : month}月${day < 10 ? '0' + day : day}日`;
      this.nowtime = `${hour}:${minute}:${second}`;
      this.nowweek = `${weekArr[week]}`
    },
    changetype(type) {
      this.type = type;
      let that = this;
      if (type == 1) {
        setTimeout(() => {
          that.initecharts();
        }, 2000)

      }
    },
    //点击地图模式上面的省份信息
    changemaplabel(shengfen) {
      console.log(shengfen);
      this.showpanel = true;
      this.map_shengfen = shengfen;
      this.map_lookjxs = true;
      this.map_lookjxsdetail = false;
      this.map_lookkh = false;
    },
    //关闭 地图模式显示的panel
    closepanel() {
      this.showpanel = false;
    },
    //点击 地图经销商模块
    changejxs(jingxiaos) {
      this.map_selectjxs = jingxiaos;
      this.map_lookjxs = false;
      this.map_lookjxsdetail = true;
      this.map_lookkh = false;
    },
    lookjsxtongjidata(item) {
      this.tongji_lookjxs = true;
      this.tongji_lookcj = false;
      this.showddmask = true;
      let that = this;
      setTimeout(() => {
        that.initechartsbyjxs();
        that.showddmask = false;
        that.selectjxs = item[0];
      }, 2000)
    },
    tongjifanhuicj() {
    //  this.tongji_lookjxs = false;
     // this.tongji_lookcj = true;
      this.showddmask = true;
     // let that = this;
      setTimeout(() => {
        // that.initecharts();
        // that.showddmask = false;

        window.location.reload();
      }, 2000)
    }
  },
  mounted() {
    let that = this;

    that.mainbanheight = (window.innerHeight - 160) / 3;

    that.map = new BMapGL.Map("container");
    that.map.setMapStyleV2({
      styleId: "95197ee848e8510f6cdc4fc27034dde9"
    });
    that.map.centerAndZoom(
      new BMapGL.Point(102.7977513, 32.28767026120888),
      5
    );
    that.map.enableScrollWheelZoom(true);

    that.map.addEventListener("click", function (e) {
      var point = new BMapGL.Point(e.latlng.lng, e.latlng.lat);
      var gc = new BMapGL.Geocoder();
      gc.getLocation(point, function (rs) { });
    });

    setTimeout(() => {
      that.map.addDistrictLayer(new BMapGL.DistrictLayer({
        name: '(江苏省)',
        kind: 0,
        fillColor: '#618bf8',
        strokeColor: '#daeafa',
      }));

      that.map.addDistrictLayer(new BMapGL.DistrictLayer({
        name: '(安徽省)',
        kind: 0,
        fillColor: '#618bf8',
        strokeColor: '#daeafa',

      }));

      // that.map.addDistrictLayer(new BMapGL.DistrictLayer({
      //   name: '(广东省)',
      //   kind: 0,
      //   fillColor: '#618bf8',
      //   strokeColor: '#daeafa',

      // }));

      const opts = {
        position: new BMapGL.Point(117.2350, 31.7837), // 指定文本标注所在的地理位置
        //offset: new BMapGL.Size(30, -30) // 设置文本偏移量
      };
      // 创建文本标注对象
      const label = new BMapGL.Label('安徽省5家', opts);
      // 自定义文本标注样式
      label.setStyle({
        color: 'blue',
        borderRadius: '5px',
        borderColor: '#ccc',
        padding: '5px',
        fontSize: '14px',
        height: '20px',
        lineHeight: '20px',
        fontFamily: '微软雅黑'
      });
      that.map.addOverlay(label);

      label.addEventListener('click', res => {
        that.changemaplabel('安徽省');
      })

      const optsbynanj = {
        position: new BMapGL.Point(118.9274, 33.6305), // 指定文本标注所在的地理位置
        //offset: new BMapGL.Size(30, -30) // 设置文本偏移量
      };
      // 创建文本标注对象
      const labelbynanj = new BMapGL.Label('江苏省7家', optsbynanj);
      // 自定义文本标注样式
      labelbynanj.setStyle({
        color: 'blue',
        borderRadius: '5px',
        borderColor: '#ccc',
        padding: '5px',
        fontSize: '14px',
        height: '20px',
        lineHeight: '20px',
        fontFamily: '微软雅黑'
      });
      that.map.addOverlay(labelbynanj);

      labelbynanj.addEventListener('click', res => {
        that.changemaplabel('江苏省');
      })

      // const optsbyguangd = {
      //   position: new BMapGL.Point(113.0524, 23.3417), // 指定文本标注所在的地理位置
      //   //offset: new BMapGL.Size(30, -30) // 设置文本偏移量
      // };
       
      // const labelbyguangzhou = new BMapGL.Label('广东省3家', optsbyguangd);
 
      // labelbyguangzhou.setStyle({
      //   color: 'blue',
      //   borderRadius: '5px',
      //   borderColor: '#ccc',
      //   padding: '5px',
      //   fontSize: '14px',
      //   height: '20px',
      //   lineHeight: '20px',
      //   fontFamily: '微软雅黑'
      // });
      // that.map.addOverlay(labelbyguangzhou);

      // labelbyguangzhou.addEventListener('click', res => {
      //   that.changemaplabel('广东省');
      // });

      //以下是标记对应的 Marker 图标
      let icon1 = new BMapGL.Icon('https://yrqny.1230551.cn/test/zsjicon.png',new BMapGL.Size(60,60));
      let pt = new BMapGL.Point(113.84939374,22.72774982);
      let marker1 = new BMapGL.Marker(pt,{
        icon:icon1,
      })

      marker1.cusdata = {
        cusname:'江苏启东注塑机厂',
        dailiname:'海天陈代理',
        shebeibm:'ZYSL-000016',
        dingweimkimei:'868120312992735',
        dangqianwz:'广东省深圳市宝安区上寮工业路,新桥107汇聚创智园西北60米',
      };
      that.map.addOverlay(marker1);

      //
       
      let pt2 = new BMapGL.Point(113.84896202,22.72753596);
      let marker2 = new BMapGL.Marker(pt2,{
        icon:icon1,
      })

      marker2.cusdata = {
        cusname:'江苏启东注塑机厂',
        dailiname:'海天陈代理',
        shebeibm:'ZYSL-000017',
        dingweimkimei:'868120312998617',
        dangqianwz:'广东省深圳市宝安区上寮工业路,新桥107汇聚创智园西北56米',
      };
      that.map.addOverlay(marker2);

      marker1.addEventListener('click', function () {
        //调用对应的弹窗
        that.cusgpsdata = this.cusdata;
        that.showcusgpsdata = true;
      });

      marker2.addEventListener('click', function () {
        //调用对应的弹窗
        that.cusgpsdata = this.cusdata;
        that.showcusgpsdata = true;
      });

    }, 3000)

  }
};
</script>

<style scoped>
#container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.titlebg {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
}

.mainbox {
  height: calc(100vh - 1.2rem);

  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 1rem;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  user-select: none;
  pointer-events: none;
}

.mainbox .mianban {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  overflow: hidden;
  pointer-events: auto;
}

.mainbox .mianban.zhongjian {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.mainbox .mianban .box-title {
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.25rem;
  height: 0.25rem;
  border-left: 0.05rem solid #fff;
  padding-left: 0.1rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  user-select: none;
}

.box-body {
  color: #fff;
  padding: 0.2rem;
}

.box-body.dd-charts {
  padding: 0rem;
}

.box-body .box-body-label {
  display: inline-block;
  padding-right: 0.2rem;
  font-size: 0.18rem;
}

.box-body .box-body-label.shout>span {
  letter-spacing: 0.28rem;
}

.box-body .box-body-value {
  display: inline-block;
  width: calc(100% - 1.1rem);
}

.box-body .box-body-value>div {
  height: 0.12rem;
  background: linear-gradient(to right, #00bdf5, #006afe);
  transition: all 0.3s;
}

.box-body-value>div>span {
  margin-top: -0.25rem;
  float: right;
  font-size: 0.18rem;
}

.box-body>div+div {
  margin-top: 0.2rem;
}

.box-body {
  overflow-y: hidden;
}

.box-body .box-body-charts,
.box-body .box-body-info {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.datetimebox {
  position: absolute;
  margin-top: 0.1rem;
  color: #fff;
  line-height: 0.4rem;
  padding-left: 0.2rem;
  z-index: 9999;
  font-size: 16px;
}

.datetimebox.tuichu {
  right: 0.15rem;

}

.datetimebox>span {

  background: #008dd6e0;
  border: 1px solid #3dfffd;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
}

.datetimebox>span.this {
  background: #21d600ad;
}

.datetimebox.tuichu>span {
  background: transparent;
  border: none;
  color: #a4acb3;
  text-decoration: underline;
}

.datetimebox.tuichu>span:hover{
  color: #fff;
}

.datetimebox.tuichu.xianse>span {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  color: #fff;
}



.agentlisttitle {
  width: 97%;
  margin-left: 1.5%;

}

.agentlisttitle>span {
  display: inline-block;
  width: 20%;
  text-align: center;
  border-top: 1px solid #00bdf5;
  border-bottom: 1px solid #00bdf5;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  margin-left: -1px;
  border-left: 1px solid #00bdf5;
  cursor: pointer;
}

.agentlisttitle>span:first-child {
  width: 40%;

}

.agentlisttitle>span:last-child {
  border-right: 1px solid #00bdf5;
  margin-left: -2px;
}

.agentlistbody.agentlisttitle>span {
  margin-top: -1px;
}

.agentlisttitle.head {
  background-color: #00bdf5;
  z-index: 999;

  position: relative;
}

.shujumoshi {
  height: calc(100% - 100px);
  margin-top: 100px;
}

.shujumoshi>div {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}

.shujumoshi>div:nth-child(2) {
  width: 40%;
}

.shujumoshi>div>div {
  height: calc(50vh - 50px);
  width: 100%;
}

.shujumoshi div.shujumoshicontent {
  padding: 60px 20px 20px 20px;
}

.shujumoshi div.chanxiaoinfo>div {
  display: inline-block;
  width: 25%;
  text-align: center;
  font-size: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.shujumoshi div.chanxiaoinfo>div>div:first-child {
  font-size: 18px;
  margin-bottom: 5px;

}

.shujumoshi div.chanxiaoinfo>div:first-child {
  background-color: #e062ae;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(2) {
  background-color: #fb7293;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(3) {
  background-color: #e690d1;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(4) {
  background-color: #32c5e9;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.pagedata>thead>tr {
  background-color: rgb(0, 186, 255);

}

.pagedata>thead>tr>th {
  text-align: left;
}

.pagedata>thead>tr>th:first-child {
  text-align: center;
}

.pagedata tr>th,
.pagedata tr>td {
  height: 35px;
  line-height: 35px;
  border: none;
}

.pagedata tr>td:first-child {
  text-align: center;
}

.pagedata tr>td:first-child>span {
  background-color: rgb(0, 186, 255);
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
}

.pagedata tr>td {
  cursor: pointer;
}

.pagedata.cusdata tr>td {
  cursor: default;
}

table.pagedata,
table.pagedata th,
table.pagedata td {
  border: none !important;
  border-collapse: collapse !important;
}

.leftpanel {
  margin-left: -25%;
  transition: all 0.5s;
}

.leftpanel.showpanel {
  margin-left: 0;
}

.leftpanel .closepanel {
  margin-left: -25%;
  transition: all 0.5s;
}

.closepanel {
  font-size: 18px;
  color: #fff;
  position: absolute;
  left: 23%;
  margin-top: 10px;
  background: #ff4a4adb;
  display: inline-block;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  line-height: 25px;
  cursor: pointer;
}

.leftpanel.showpanel .closepanel {
  margin-left: 0;
}

.dd-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}

.dd-mask>div {
  text-align: center;
  margin-top: 48vh;
  font-size: 18px;
  color: #fff;

  letter-spacing: 4px;
}

.nowjxsdata{
  position: absolute;
    color: #a4acb3;
    font-size: 16px;
    right: 1.7rem;
    top: .2rem;
    z-index: 99;
}

.shebeigpsinfo {
  width: 500px;
  margin-left: calc(50% - 250px);
  height: 360px;
  position: fixed;
  z-index: 999;
  background: #fff;
  bottom: 20px;
  background: #008dd6e0;
    border: 1px solid #3dfffd;
    overflow: hidden;
    pointer-events: auto;
    transition: all 0.5s;
}

.shebeigpsinfo.hide{
  bottom: -400px;
}

.shebeigpsinfo ul{
  padding: 20px;
  color: #fff;
}

.shebeigpsinfo ul>li{
  font-size: 18px;
  line-height: 50px;
  height: 50px;
}

.shebeigpsinfo ul>li>span{
  display: inline-block;
  width: 120px;
  text-align: right;
  color: #ccc;

}

.shebeigpsinfo .closegps{
  font-size: 18px;
    color: #fff;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    background: #ff4a4adb;
    display: inline-block;
    height: 25px;
    width: 25px;
    text-align: center;
    border-radius: 50%;
    line-height: 25px;
    cursor: pointer;
    user-select: none;
    
}
</style>