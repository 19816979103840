<template>
  <div>
    <div>
      <img class="titlebg" src="../assets/title.png" />
    </div>
    <div id="container">

    </div>


    <div :class="['datetimebox', 'tuichu', type == 1 ? '' : 'xianse']">

      <span @click="loginoutbtn">退出</span>

    </div>
    <el-row style="width: 100%;height: 100vh;background-color: #1f2e3d; color: #fff;position: absolute;z-index: 11;">
      <div class="shujumoshi">
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="设备运行情况">
              <div class="shujumoshicontent">
                <div id="shouchusbyxqkbykh" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="当班产量">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">设备名称</th>
                      <th style="width: 110px;">产量</th>
                      <th style="width: 110px;">不良品数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in alldangbanchanlbykh">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td style="cursor: pointer;" @click="lookmachinedetail(item)"> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="良品率">
              <div class="shujumoshicontent">
                <div id="khliangpinl" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="稼动率">
              <div class="shujumoshicontent">
                <div id="khjiadongl" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="项目分析">
              <div class="shujumoshicontent">
                <div id="khxiangmufx" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="当班运行时长">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">设备名称</th>
                      <th style="width: 110px;">运行时长</th>
                      <th style="width: 110px;">当班耗电量</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in alldangbanyxscbykh">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> - kW·h</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
      </div>
    </el-row>

    <nav :class="['dd-nav', shownav ? 'show' : '']" @mouseleave="mouseleave" @mouseenter="mouseenter">
      <ul>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="生产管理" placement="left">
            <a>
              <span @click="selectmodel('producesys')" class="icon iconfont">&#xe62f;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="设备数据" placement="left">
            <a>
              <span @click="selectmodel('shebeigl')" class="icon iconfont">&#xe792;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="BOM管理" placement="left">
            <a>
              <span @click="selectmodel('bomsys')" class="icon iconfont">&#xe631;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="品质管理" placement="left">
            <a>
              <span @click="selectmodel('qualitysys')" class="icon iconfont">&#xe61c;</span>

            </a>
          </el-tooltip>
        </li>

        <li>
          <el-tooltip class="item" effect="dark" content="系统日志" placement="left">
            <a>
              <span @click="selectmodel('syslog')" class="icon iconfont">&#xe66a;</span>

            </a>
          </el-tooltip>
        </li>
        <li>
          <el-tooltip class="item" effect="dark" content="系统管理" placement="left">
            <a>
              <span @click="selectmodel('usersys')" class="icon iconfont">&#xe69b;</span>

            </a>
          </el-tooltip>
        </li>
      </ul>
    </nav>

    <div class="dd-mask" v-if="showmathinedetail" @click="showmathinedetail = false">
      <div :style="tabidx == 0?'width:400px;margin-left: calc(50% - 200px);':'width:600px;margin-left:  calc(50% - 300px);'" style="transition: all 0.3s;" class="dd-mask-body" @click.stop="() => { return false; }">

        <div class="dd-tab">
          <div :class="tabidx == 0 ? 'this' : ''" @click="tabidx = 0">机台信息</div>
          <div :class="tabidx == 1 ? 'this' : ''" @click="tabidx = 1">稼动率</div>
        </div>
        <div class="dd-tab-body" style="margin-top: 10px;">

          <div v-if="tabidx == 0">
            <div style="width: 360px; padding-left: 20px;" class="dd-tab-body-jitaiinfo">
            <div class="zhuangtaihe">
              <span>{{ nowmachinedetail.machineno }}</span>
              <span :class="nowmachinedetail.state">{{ nowmachinedetail.statedesc }}</span>
            </div>
            <div>
              <span>操作模式:</span>
              <span>{{ nowmachinedetail.caozuoms }}</span>
            </div>
            <div>
              <span>模具编号:</span>
              <span>{{ nowmachinedetail.bom.Mohao }}</span>
            </div>
            <div>
              <span>产品名称:</span>
              <span>{{ nowmachinedetail.bom.Pinhao }}</span>
            </div>
            <div>
              <span>订单良品数:</span>
              <span> {{ nowmachinedetail.dangbancl }} </span>
            </div>
            <div>
              <span>订单计划数:</span>
              <span>{{ nowmachinedetail.bom.Dingdanshu }}</span>
            </div>
            <div>
              <span>实际生产周期(秒):</span>
              <span>{{ nowmachinedetail.shijisczq }}</span>
            </div>
            <div>
              <span>订单计划结束时间:</span>
              <span>{{ nowmachinedetail.jihuajssj }}</span>
            </div>
            <div>
              <span>状态持续时间(小时):</span>
              <span> {{ nowmachinedetail.zhuangtaicxsj }}</span>
            </div>
            <div>
              <span>耗电量(kW·h):</span>
              <span> -</span>
            </div>
            <div>
              <span>订单达成率:</span>
              <span>{{ nowmachinedetail.dingdandcl }}%</span>
            </div>
            <div>
              <el-progress :percentage="nowmachinedetail.dingdandcl" :show-text="false"
                :format="formatprogress"></el-progress>
            </div>
            <div>
              <span>稼动率:</span>
              <span>{{ nowmachinedetail.jiadonglv }}%</span>
            </div>
            <div>
              <el-progress :percentage="nowmachinedetail.jiadonglv" text-color="#fff" color="#67c23a" :show-text="false"
                :format="formatprogress"></el-progress>
            </div>
          </div>
          </div>
          <div v-if="tabidx == 1">
             <div style="height: 30px;line-height: 30px;color: #ccc;">
              总稼动率：<span>{{nowjiadonglv.heji == 'NaN%'?'-':nowjiadonglv.heji}}</span>
             </div>
             <div>
              <table border style="width: 100%;border-color: #ccc;" class="jiadonglvtable">
                <thead>
                  <tr>
                    <th style="width: 12.5%;">时间段</th>
                    <th style="width: 12.5%;">稼动率</th>
                    <th style="width: 12.5%;">时间段</th>
                    <th style="width: 12.5%;">稼动率</th>
                    <th style="width: 12.5%;">时间段</th>
                    <th style="width: 12.5%;">稼动率</th>
                    <th style="width: 12.5%;">时间段</th>
                    <th style="width: 12.5%;">稼动率</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>00:00</td>
                      <td>{{nowjiadonglv._0000}}</td>
                      <td>06:00</td>
                      <td>{{nowjiadonglv._0600}}</td>
                      <td>12:00</td>
                      <td>{{nowjiadonglv._1200}}</td>
                      <td>18:00</td>
                      <td>{{nowjiadonglv._1800}}</td>
                    </tr>
                    <tr>
                      <td>00:30</td>
                      <td>{{nowjiadonglv._0030}}</td>
                      <td>06:30</td>
                      <td>{{nowjiadonglv._0630}}</td>
                      <td>12:30</td>
                      <td>{{nowjiadonglv._1230}}</td>
                      <td>18:30</td>
                      <td>{{nowjiadonglv._1830}}</td>
                    </tr>

                    <tr>
                      <td>01:00</td>
                      <td>{{nowjiadonglv._0100}}</td>
                      <td>07:00</td>
                      <td>{{nowjiadonglv._0700}}</td>
                      <td>13:00</td>
                      <td>{{nowjiadonglv._1300}}</td>
                      <td>19:00</td>
                      <td>{{nowjiadonglv._1900}}</td>
                    </tr>
                    <tr>
                      <td>01:30</td>
                      <td>{{nowjiadonglv._0130}}</td>
                      <td>07:30</td>
                      <td>{{nowjiadonglv._0730}}</td>
                      <td>13:30</td>
                      <td>{{nowjiadonglv._1330}}</td>
                      <td>19:30</td>
                      <td>{{nowjiadonglv._1930}}</td>
                    </tr>

                    <tr>
                      <td>02:00</td>
                      <td>{{nowjiadonglv._0200}}</td>
                      <td>08:00</td>
                      <td>{{nowjiadonglv._0800}}</td>
                      <td>14:00</td>
                      <td>{{nowjiadonglv._1400}}</td>
                      <td>20:00</td>
                      <td>{{nowjiadonglv._2000}}</td>
                    </tr>
                    <tr>
                      <td>02:30</td>
                      <td>{{nowjiadonglv._0230}}</td>
                      <td>08:30</td>
                      <td>{{nowjiadonglv._0830}}</td>
                      <td>14:30</td>
                      <td>{{nowjiadonglv._1430}}</td>
                      <td>20:30</td>
                      <td>{{nowjiadonglv._2030}}</td>
                    </tr>

                    <tr>
                      <td>03:00</td>
                      <td>{{nowjiadonglv._0300}}</td>
                      <td>09:00</td>
                      <td>{{nowjiadonglv._0900}}</td>
                      <td>15:00</td>
                      <td>{{nowjiadonglv._1500}}</td>
                      <td>21:00</td>
                      <td>{{nowjiadonglv._2100}}</td>
                    </tr>
                    <tr>
                      <td>03:30</td>
                      <td>{{nowjiadonglv._0330}}</td>
                      <td>09:30</td>
                      <td>{{nowjiadonglv._0930}}</td>
                      <td>15:30</td>
                      <td>{{nowjiadonglv._1530}}</td>
                      <td>21:30</td>
                      <td>{{nowjiadonglv._2130}}</td>
                    </tr>

                    <tr>
                      <td>04:00</td>
                      <td>{{nowjiadonglv._0400}}</td>
                      <td>10:00</td>
                      <td>{{nowjiadonglv._1000}}</td>
                      <td>16:00</td>
                      <td>{{nowjiadonglv._1600}}</td>
                      <td>22:00</td>
                      <td>{{nowjiadonglv._2200}}</td>
                    </tr>
                    <tr>
                      <td>04:30</td>
                      <td>{{nowjiadonglv._0430}}</td>
                      <td>10:30</td>
                      <td>{{nowjiadonglv._1030}}</td>
                      <td>16:30</td>
                      <td>{{nowjiadonglv._1630}}</td>
                      <td>22:30</td>
                      <td>{{nowjiadonglv._2230}}</td>
                    </tr>

                    <tr>
                      <td>05:00</td>
                      <td>{{nowjiadonglv._0500}}</td>
                      <td>11:00</td>
                      <td>{{nowjiadonglv._1100}}</td>
                      <td>17:00</td>
                      <td>{{nowjiadonglv._1700}}</td>
                      <td>23:00</td>
                      <td>{{nowjiadonglv._2300}}</td>
                    </tr>
                    <tr>
                      <td>05:30</td>
                      <td>{{nowjiadonglv._0530}}</td>
                      <td>11:30</td>
                      <td>{{nowjiadonglv._1130}}</td>
                      <td>17:30</td>
                      <td>{{nowjiadonglv._1730}}</td>
                      <td>23:30</td>
                      <td>{{nowjiadonglv._2330}}</td>
                    </tr>
                  </tbody>
              </table>
             </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>

import { gethomeinfo, home_cus,getmachineinfotohome } from '../api/api';
import * as echarts from 'echarts';

export default {
  data() {
    return {
      mainbanheight: 0,
      value: new Date(),
      nowdate: 0,

      riqilist: [],
      today: 0,
      shownav: false,
      showtimer: null,
      nianyue: '',
      nowtime: '',
      nowweek: '',
      timexdata: [],

      timer: null,
      userinfo: null,

      type: 0, //1 展示退出按钮

      //一下是图表
      alldangbanchanlbykh: [], //当班产量
      alldangbanyxscbykh: [],  //当班运行时长
      shebeiyxqk: [],  //设备运行情况
      liangpinl: [], //良品率
      jiadongl: [],  //稼动率
      xiangmufx: [], //项目分析

      //以下是展示对应的单台设备数据信息的属性
      showmathinedetail: false, //是否展示单台设备数据信息
      tabidx: 0,
      nowmachinedetail: {   //当前查看设备的信息数据
        bom: {},
        dangbancl:99
      },
      nowjiadonglv:{},  //当前查看设备的稼动率

    };
  },
  created() {
    let that = this;

    this.userinfo = JSON.parse(sessionStorage.getItem("user"));


    this.gethomedata();

    // setTimeout(() => {
    //   that.initecharts();
    // }, 2000)

  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    formatprogress() {
      return "";
    },
    /* 退出系统*/
    loginoutbtn() {
      let that = this;

      this.$confirm('确认要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '正在退出登录...',
          });
          setTimeout(() => {
            sessionStorage.clear();
            that.$router.push({ path: '/login' });
          }, 1000)

        })
        .catch(() => {

        })
    },
    gethomedata() {
      let that = this;
      home_cus({ userid: this.userinfo.Id }).then(res => {

        that.timexdata = res.data.datalist;
        that.alldangbanchanlbykh = res.data.dangbancl;
        that.alldangbanyxscbykh = res.data.dangbanyxsc;

        that.shebeiyxqk = res.data.shebeiyxqk;
        that.liangpinl = res.data.liangpinl;
        that.jiadongl = res.data.jiadongl;
        that.xiangmufx = res.data.xiangmufx;

        setTimeout(() => {
          that.initecharts();
        }, 1000)
      })
    },

    mouseenter() {
      this.shownav = true;
      clearTimeout(this.showtimer);
    },
    mouseleave() {
      let that = this;
      this.showtimer = setTimeout(() => {
        that.shownav = false;
      }, 5000)
    },
    selectmodel(selectitem) {
      sessionStorage.setItem("selectmenu", selectitem);
      this.$router.push({ path: '/home' });

    },
    initecharts() {

      //设备运行情况
      let shouchusbyxqk = echarts.init(document.getElementById('shouchusbyxqkbykh'));

      const optionshouchusbyxqk = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['离线', '故障', '正常运行', '设备总量'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: [
          {
            name: '设备运行情况',
            type: 'bar',
            data: this.shebeiyxqk
          }

        ],
        legend: {
          data: ['设备运行情况'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      shouchusbyxqk.setOption(optionshouchusbyxqk);

      //良品率
      let khliangpinl = echarts.init(document.getElementById('khliangpinl'));
      const optionkhliangpinl = {
        color: ['red'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['良品率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.timexdata,
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '良品率(%)',
            min: 0,
            max: 100,

            axisLabel: {
              formatter: '{value} (%)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [


          {
            name: '良品率',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (%)';
              }
            },
            data: this.liangpinl
          }
        ]
      };

      khliangpinl.setOption(optionkhliangpinl);

      //稼动率 khjiadongl
      let khjiadongl = echarts.init(document.getElementById('khjiadongl'));
      const optionkhjiadongl = {
        color: ['red'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['稼动率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.timexdata,
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '稼动率(%)',
            min: 0,
            max: 100,

            axisLabel: {
              formatter: '{value} (%)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [
          {
            name: '稼动率',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (%)';
              }
            },
            data: this.jiadongl
          }
        ]
      };

      khjiadongl.setOption(optionkhjiadongl);

      //khxiangmufx 项目分析
      let khxiangmufx = echarts.init(document.getElementById('khxiangmufx'));

      const optionkhxiangmufx = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['首件等待', '换单提醒', '超成型周期', '异常停机', '不良报警', '待停机数'],
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          },

        },
        series: [
          {
            name: '项目分析',
            type: 'bar',
            data: this.xiangmufx
          }

        ],
        legend: {
          data: ['项目分析'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      khxiangmufx.setOption(optionkhxiangmufx);

    },
    lookmachinedetail(item) {
      console.log(item);
      
      let that = this;
      //在这里获取数据
      getmachineinfotohome({id :item[3]}).then(res=>{
        console.log(res);
        that.nowmachinedetail = res.data.machineinfo;
        that.nowjiadonglv = res.data.jiadonglv[0];
        that.tabidx = 0;
        that.showmathinedetail = true;
      })
      
    }

  },
  mounted() {

  }
};
</script>

<style scoped>
#container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.titlebg {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
}

.mainbox {
  height: calc(100vh - 1.2rem);

  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 1rem;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  user-select: none;
  pointer-events: none;
}



.datetimebox {
  position: absolute;
  margin-top: 0.1rem;
  color: #fff;
  line-height: 0.4rem;
  padding-left: 0.2rem;
  z-index: 9999;
  font-size: 16px;
}

.datetimebox.tuichu {
  right: 0.15rem;

}

.datetimebox>span {

  background: #008dd6e0;
  border: 1px solid #3dfffd;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
}

.datetimebox>span.this {
  background: #21d600ad;
}

.datetimebox.tuichu>span {
  background: transparent;
  border: none;
  color: #a4acb3;
}

.datetimebox.tuichu.xianse>span {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  color: #fff;
}

.shujumoshi {
  height: calc(100% - 100px);
  margin-top: 100px;
}

.shujumoshi>div {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}

.shujumoshi>div:nth-child(2) {
  width: 40%;
}

.shujumoshi>div>div {
  height: calc(50vh - 50px);
  width: 100%;
}

.shujumoshi div.shujumoshicontent {
  padding: 60px 20px 20px 20px;
}

.pagedata>thead>tr {
  background-color: rgb(0, 186, 255);

}

.pagedata>thead>tr>th {
  text-align: left;
}

.pagedata>thead>tr>th:first-child {
  text-align: center;
}

.pagedata tr>th,
.pagedata tr>td {
  height: 35px;
  line-height: 35px;
  border: none;
}

.pagedata tr>td:first-child {
  text-align: center;
}

.pagedata tr>td:first-child>span {
  background-color: rgb(0, 186, 255);
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
}

.pagedata tr>td {}

table.pagedata,
table.pagedata th,
table.pagedata td {
  border: none !important;
  border-collapse: collapse !important;
}

.dd-mask-body {
  width: 80%;
  height: 500px;
  background: #1f2e3d;
  margin-left: 10%;
  margin-top: 200px;
  border: 1px solid #3dfffd;
  padding: 20px;
  border-radius: 20px;
  color: #fff
}

.dd-tab>div {
  display: inline-block;
  width: 100px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #3dfffd;
  border-radius: 5px;
  color: #fff
}

.dd-tab>div.this,
.dd-tab>div:hover {
  background: #21d600ad;
  border: 1px solid #3dfffd;
  color: #fff;
}

.dd-tab-body-jitaiinfo>div{
  height: 30px;
  line-height: 30px;
}

.dd-tab-body-jitaiinfo>div>span:first-child{
  color: #ccc;
}

.dd-tab-body-jitaiinfo>div>span:last-child{
  color: #fff;
    float: right;
    margin-right: 5px;
}

.jiadonglvtable td,.jiadonglvtable th,.jiadonglvtable {
  height: 26px;
  line-height: 26px;
  border: 1px solid #666;
  border-collapse: collapse; /* 消除双边框效果 */
  padding-left: 10px;
}

.jiadonglvtable td:nth-child(2n-1){
  color: #ccc;
}
</style>