<template>
  <div>
    <div>
      <img
        class="titlebg"
        src="../assets/title.png"
      />
    </div>
    <div id="container">

    </div>
    <div class="datetimebox">
      <div>{{nowtime}}</div>
      <div>{{nianyue}}</div>
    </div>
    <el-row
      :gutter="20"
      class="mainbox"
    >
      <el-col :span="6">
        <div
          class="mianban"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            中研云设备管理平台
          </div>
          <div class="box-body">
            <div>
              <div class="box-body-label">设备总量</div>
              <div class="box-body-value">
                <div clas="">
                  <span>{{homedata.shebeiinfo.all}}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">正常运行</div>
              <div class="box-body-value"  >
                <div clas="" :style="{'width':(homedata.shebeiinfo.run==0?'0.3rem':(homedata.shebeiinfo.run/homedata.shebeiinfo.all *100)  + '%')}">
                  <span>{{homedata.shebeiinfo.run}}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>故</span>障</div>
              <div class="box-body-value" >
                <div clas="" :style="{'width':(homedata.shebeiinfo.guzhang==0?'0.3rem':(homedata.shebeiinfo.guzhang/homedata.shebeiinfo.all *100) + '%')}">
                  <span>{{homedata.shebeiinfo.guzhang}}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>离</span>线</div>
              <div class="box-body-value">
                <div clas="" :style="{'width':(homedata.shebeiinfo.lixian==0?'0.3rem':(homedata.shebeiinfo.lixian/homedata.shebeiinfo.all *100) + '%')}">
                  <span>{{homedata.shebeiinfo.lixian}}台</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col
        :span="6"
        :offset="12"
      >
        <div
          class="mianban"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            良品率(%)
          </div>
          <div class="box-body dd-charts">
            <div class="box-body-charts">
              <div
                id="liangpl"
                :style="{width: '100%', height: '1.9rem'}"
              ></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div
          class="mianban zhongjian"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            当班产量
          </div>
          <div class="box-body dd-charts">
            <div class="box-body-charts">
              <div
                id="myChart"
                :style="{width: '100%', height: '1.9rem'}"
              ></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col
        :span="6"
        :offset="12"
      >
        <div
          class="mianban zhongjian"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            当班运行时长
          </div>
          <div class="box-body dd-charts">
            <div class="box-body-charts">
              <div
                id="myChartByShic"
                :style="{width: '100%', height: '1.9rem'}"
              ></div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="6">
        <div
          class="mianban"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            项目分析
          </div>
          <div class="box-body ">
            <div>
              <div class="box-body-label">首件等待</div>
              <div class="box-body-value" style="width:0.3rem;">
                <div clas="">
                  <span>0台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">换单提醒</div>
              <div class="box-body-value" style="width:0.3rem;">
                <div clas="">
                  <span>0台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">超成型周期</div>
              <div class="box-body-value" style="width:0.3rem;">
                <div clas="">
                  <span>0台</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div
          class="mianban"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            稼动率(%)
          </div>
          <div class="box-body dd-charts">
            <div class="box-body-charts">
              <div
                id="shijianjdl"
                :style="{width: '100%', height: '1.9rem'}"
              ></div>
            </div>
          </div>
        </div>
      </el-col>
      
      <el-col :span="6">
        <div
          class="mianban"
          :style="{'height':mainbanheight+'px'}"
        >
          <div class="box-title">
            报警日历
          </div>
          <div class="box-body">
            <div class="dd-rili">
              <table>
                <tr>
                  <th v-for="(item,index) in weeklist" :key="index">{{item}}</th>
                </tr>
                <tr v-for="(item,index) in 5" :key="index">
                  <td :class="[index ==0 && riqilist[(index * 7) + idx] > 8?'notdqy':'',index ==4 && riqilist[(index * 7) + idx] < 8?'notdqy':'',today == riqilist[(index * 7)+ idx]?'today':'']" v-for="(itm,idx) in 7" :key="idx">{{riqilist[(index * 7) + idx]}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <nav :class="['dd-nav',shownav?'show':'']" @mouseleave="mouseleave" @mouseenter="mouseenter">
        <ul>
          <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3 ">
              <el-tooltip  class="item" effect="dark" content="生产管理" placement="left">
                <a>
                  <span @click="selectmodel('producesys')" class="icon iconfont">&#xe62f;</span>

                </a>
              </el-tooltip>
          </li>
           <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3 " >
              <el-tooltip  class="item" effect="dark" content="设备管理" placement="left">
                <a>
                  <span @click="selectmodel('shebeigl')"  class="icon iconfont">&#xe792;</span>

                </a>
              </el-tooltip>
          </li>
           <li v-if="userinfo.UserType == 3 ">
              <el-tooltip  class="item" effect="dark" content="BOM管理" placement="left">
                <a>
                 <span  @click="selectmodel('bomsys')" class="icon iconfont">&#xe631;</span>

                </a>
              </el-tooltip>
          </li>
           <li v-if=" userinfo.UserType == 3 ">
              <el-tooltip  class="item" effect="dark" content="品质管理" placement="left">
                <a>
                  <span @click="selectmodel('qualitysys')" class="icon iconfont">&#xe61c;</span>

                </a>
              </el-tooltip>
          </li>
            
          <li>
              <el-tooltip class="item" effect="dark" content="系统日志" placement="left">
                <a>
                 <span @click="selectmodel('syslog')"  class="icon iconfont">&#xe66a;</span>

                </a>
              </el-tooltip>
          </li>
          <li>
              <el-tooltip class="item" effect="dark" content="系统管理" placement="left">
                <a>
                 <span @click="selectmodel('usersys')"   class="icon iconfont">&#xe69b;</span>

                </a>
              </el-tooltip>
          </li>
        </ul>
      </nav>
    
  </div>
</template>
<script>

import {gethomeinfo} from '../api/api';

export default {
  data() {
    return {
      mainbanheight: 0,
      value: new Date(),
      nowdate:0,
      weeklist:['周日','周一','周二','周三','周四','周五','周六'],
      riqilist:[],
      today:0,
      shownav :false,
      showtimer:null,
      nianyue:'',
      nowtime:'',
      nowweek:'',
      timexdata:[],
      homedata:{
        shebeiinfo:{}
      },
      timer:null,
      userinfo:null
    };
  },
  created() {
    let that = this;
    this.currentTime();
 
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));

    let nowdate = (new Date);
    let today = nowdate.getDate();
    this.today = today;
    let todayByWeek =  nowdate.getDay();

    //获取当前月份多少天
    let mouthday = this.utils.getDate();

    //获取上个月份多少天

    let lastmouthday = this.utils.getDate(nowdate.getFullYear(),nowdate.getMonth());
    
    
    // 找出本月月初是周几
    let yuechu = new Date(`${nowdate.getFullYear()}-${nowdate.getMonth()+1}-1`);
    let yuechuByWeek = yuechu.getDay();

    //生成日历数据

    for(let i=0;i<yuechuByWeek; i++){
      this.riqilist.push(lastmouthday - yuechuByWeek +i + 1);
    }

    let for_i = 0;
    for(let i = yuechuByWeek; i <= mouthday;i++){
      for_i ++;
      this.riqilist.push(for_i );
    }

    this.riqilist.push(mouthday);

    for(let i = 0;i<(35 - mouthday - yuechuByWeek); i++ ){
      this.riqilist.push(i+1);
    }

    this.gethomedata();
    
    if(this.timer){
      clearInterval(this.timer);
    }

    this.timer = setInterval(()=>{
        that.gethomedata();
    },3e3);
  },
  destroyed(){
    clearInterval(this.timer);
  },
  methods: {
    gethomedata(){
      let that = this;
      gethomeinfo().then(res=>{
      
        that.homedata = res.data;
        that.timexdata = res.data.xzhou;

        that.showdangbzw();
        that.showdangbansc();
        that.showxiangmufx();
        that.showshijianjdl();
        
        that.showlliangpl();
        
      })
    },

    showdangbzw() {
      // let divc = document.getElementById('myChart');
      // divc.innerHTML = '';

      let myChart = this.$echarts.init(document.getElementById("myChart"));

      let option = {
        tooltip: {
          trigger: "item"
        },
        color: [
          "#8256e9",
          "#eb6f49",
          "#00d98b",
          "#fdb626",
          "#00e4ed",
          "#5e97e6",
          "#43a923"
        ],
        legend: {
          textStyle: {
            color: "#fff"
          },
          type: "scroll",
          align: "left",
          orient: "vertical",
          right: "5%"
        },

        series: [
          {
            name: "当班产量",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },

            labelLine: {
              show: false
            },
            data: this.homedata.marchinechanliang
          }
        ]
      };

      // 绘制图表
      myChart.setOption(option);
    },
    showdangbansc() {
      let myChart = this.$echarts.init(
        document.getElementById("myChartByShic")
      );

      let option = {
        tooltip: {
          trigger: "item"
        },
        color: [
          "#8256e9",
          "#eb6f49",
          "#00d98b",
          "#fdb626",
          "#00e4ed",
          "#5e97e6",
          "#43a923"
        ],
        legend: {
          textStyle: {
            color: "#fff"
          },
          type: "scroll",
          align: "left",
          orient: "vertical",
          right: "5%"
        },

        series: [
          {
            name: "当班时长",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },

            labelLine: {
              show: false
            },
            data:this.homedata.marchineruntime
          }
        ]
      };

      // 绘制图表
      myChart.setOption(option);
    },
    showxiangmufx() {
       
    },
    showshijianjdl(){
      let that = this;
      let myChart = this.$echarts.init(document.getElementById("shijianjdl"));

      let option = {
            legend: {
                show: false,
                top: '0',
                right:'1%',
                data: ['稼动率'],
                icon: 'circle',
                textStyle: {
                    color: "#fff",
                    fontSize: 12
                }
            },
            grid: {
                "top": "15%",
                "bottom": "15%",
                "right": "5%",
                "left": "12%"
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            xAxis: {
                data: that.timexdata,
                axisLine: { show: false },
                axisTick: {
                    show: false
                },
                axisLabel: { //X轴标签
                    show: true,
                    textStyle: {
                        color: '#fff',//字体颜色
                        fontSize: 12 //字体大小
                    }
                }
 
 
            },
            yAxis: [
                {
                    type: 'value',
                    name: '',
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: 12,
                    },
                    min: 0,
                    max: 100,
                    interval: 20,
                    lineStyle: {
                        color: '#fff',
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//字体颜色
                            fontSize: 12 //字体大小
                        }
 
                    },
                    axisTick: { show: false },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff',
                        }
                    },
                    splitLine: { //Y轴分隔符
                        show: true,
                        lineStyle: {
                            color: '#fff',
                        }
 
                    }
 
                },
 
            ],
            series: [
                {
                    smooth: true,
                    areaStyle: {
                        normal: {
                            // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            //     offset: 0,
                            //     color: '#5d98eb'
                            // },
                            // {
                            //     offset: 1,
                            //     color: 'rgba(126,171,233,0)'
                            // }
                            // ], false),
                            shadowColor: 'rgba(44,118,222, 0.8)',
                            shadowBlur: 20
                        }
                    },
                    name: '稼动率',
                    type: 'line',
                    data:  this.homedata.jiadonglv,
                    itemStyle: {
                        normal: {
                            color: '#1476e8',
                            label: {
                                show: false, //开启显示
                                position: 'bottom', //在下方显示
                                textStyle: { //数值样式
                                    color: '#65ABE7',
                                    fontSize: 16
                                }
                            }
                        }
                    }
                },
                
            ]
        };
 
      myChart.setOption(option);
    },
     
    showlliangpl(){
      let myChart = this.$echarts.init(document.getElementById("liangpl"));

      let option = {
            legend: {
                show: false,
                top: '0',
                right:'1%',
                data: ['良品率'],
                icon: 'circle',
                textStyle: {
                    color: "#fff",
                    fontSize: 12
                }
            },
            grid: {
                "top": "15%",
                "bottom": "15%",
                "right": "5%",
                "left": "12%"
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            xAxis: {
                data:this.timexdata,
                axisLine: { show: false },
                axisTick: {
                    show: false
                },
                axisLabel: { //X轴标签
                    show: true,
                    textStyle: {
                        color: '#fff',//字体颜色
                        fontSize: 12 //字体大小
                    }
                }
 
 
            },
            yAxis: [
                {
                    type: 'value',
                    name: '',
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: 12,
                    },
                    min: 0,
                    max: 100,
                    interval: 20,
                    lineStyle: {
                        color: '#fff',
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//字体颜色
                            fontSize: 12 //字体大小
                        }
 
                    },
                    axisTick: { show: false },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff',
                        }
                    },
                    splitLine: { //Y轴分隔符
                        show: true,
                        lineStyle: {
                            color: '#fff',
                        }
 
                    }
 
                },
 
            ],
            series: [
                {
                    smooth: true,
                    areaStyle: {
                        normal: {
                           
                            shadowColor: 'rgba(44,118,222, 0.8)',
                            shadowBlur: 20
                        }
                    },
                    name: '良品率',
                    type: 'line',
                    data: this.homedata.liangpinlv,
                    itemStyle: {
                        normal: {
                            color: '#1476e8',
                            label: {
                                show: false, //开启显示
                                position: 'bottom', //在下方显示
                                textStyle: { //数值样式
                                    color: '#65ABE7',
                                    fontSize: 16
                                }
                            }
                        }
                    }
                },
                
            ]
        };
 
      myChart.setOption(option);
    },

    mouseenter(){
      this.shownav = true;
      clearTimeout(this.showtimer);
    },
    mouseleave(){
      let that = this;
      this.showtimer = setTimeout(()=>{
        that.shownav = false;
      },5000)
    },
     selectmodel(selectitem){
      
      
      sessionStorage.setItem("selectmenu",selectitem);
      this.$router.push({ path: '/home' });

    },

    currentTime() {
      this.datetimer = setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.nianyue = `${year}年${month < 10?'0' + month :month}月${day < 10?'0' + day :day}日`;
      this.nowtime = `${hour}:${minute}:${second}`;
      this.nowweek = `${weekArr[week]}`
    },
  },
  mounted() {
    let that = this;

    that.mainbanheight = (window.innerHeight - 160) / 3;

    var map = new BMapGL.Map("container");
    map.setMapStyleV2({
      styleId: "95197ee848e8510f6cdc4fc27034dde9"
    });
    map.centerAndZoom(
      new BMapGL.Point(102.7977513, 32.28767026120888),
      5
    );
    map.enableScrollWheelZoom(true);

    map.addEventListener("click", function(e) {
      var point = new BMapGL.Point(e.latlng.lng, e.latlng.lat);
      var gc = new BMapGL.Geocoder();
      gc.getLocation(point, function(rs) {});
    });
  }
};
</script>

<style scoped>
#container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.titlebg {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
}

.mainbox {
  height: calc(100vh - 1.2rem);

  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 1rem;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  user-select: none;
  pointer-events: none;
}

.mainbox .mianban {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  overflow: hidden;
  pointer-events: auto;
}

.mainbox .mianban.zhongjian {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.mainbox .mianban .box-title {
  color: #fff;
  font-size: 0.18rem;
  line-height: 0.25rem;
  height: 0.25rem;
  border-left: 0.05rem solid #fff;
  padding-left: 0.1rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  user-select: none;
}

.box-body {
  color: #fff;
  padding: 0.2rem;
}

.box-body.dd-charts{
  padding:0rem;
}

.box-body .box-body-label {
  display: inline-block;
  padding-right: 0.2rem;
  font-size: 0.14rem;
}

.box-body .box-body-label.shout > span {
  letter-spacing: 0.28rem;
}

.box-body .box-body-value {
  display: inline-block;
  width: calc(100% - 1.1rem);
}

.box-body .box-body-value > div {
  height: 0.12rem;
  background: linear-gradient(to right, #00bdf5, #006afe);
  transition:  all 0.3s;
}

.box-body-value > div > span {
  margin-top: -0.25rem;
  float: right;
  font-size: 0.14rem;
}

.box-body > div + div {
  margin-top: 0.2rem;
}
.box-body {
  overflow-y: hidden;
}

.box-body .box-body-charts,
.box-body .box-body-info {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.datetimebox{
      position: absolute;
    width: 2rem;
    right: 0.15rem;
    
    margin-top: 0.1rem;
    background: #008dd6e0;
    border: 1px solid #3dfffd;
    color: #fff;
    height: 0.8rem;
    line-height: 0.4rem;
    padding-left: 0.2rem;
     
    
    font-size: 0.26rem;
}
</style>