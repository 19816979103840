import axios from 'axios';
import { getBaseUrl } from '@/config'

let base = getBaseUrl();

export const requestLogin = params => { return axios.get(`${base}api/app/login`, {params:params} ).then(res => res.data); };

export const getalldep = params => { return axios.get(`${base}api/app/getdeps`,  {params:params}).then(res => res.data); };

export const editdep = params => { return axios.post(`${base}api/app/editdep`, params).then(res => res.data); };

export const deldep = params => { return axios.get(`${base}api/app/deldep`, {params:params}).then(res => res.data); };

export const getallrole = params => {return axios.get(`${base}api/app/getroles`,{params:params}).then(res =>res.data);}

export const editrole = params => {return axios.post(`${base}api/app/editrole`,params).then(res =>res.data);}

export const delrole = params => {return axios.get(`${base}api/app/delrole`,{params:params}).then(res =>res.data);}

export const getalluser = params => {return axios.get(`${base}api/app/getalluser`,{params:params}).then(res =>res.data);}

//getallcjuser
export const getallcjuser = params => {return axios.get(`${base}api/app/getallcjuser`,{params:params}).then(res =>res.data);}

//getalldlsuser
export const getalldlsuser = params => {return axios.get(`${base}api/app/getalldlsuser`,{params:params}).then(res =>res.data);}

//getallkhuser
export const getallkhuser = params => {return axios.get(`${base}api/app/getallkhuser`,{params:params}).then(res =>res.data);}

export const edituser = params => {return axios.post(`${base}api/app/edituser`,params).then(res =>res.data);}

export const deluser = params => {return axios.get(`${base}api/app/deluser`,{params:params}).then(res =>res.data);}

export const chongzhimm = params => {return axios.get(`${base}api/app/chongzhi`,{params:params}).then(res =>res.data);}

export const getallmarchine = params =>{return axios.get(`${base}api/app/getmarchinelist`,{params:params}).then(res =>res.data);}

export const editmarchine = params => {return axios.post(`${base}api/app/editmachine`,params).then(res =>res.data);}

//获取当前设备阈值信息 api/app/getthreshold
export const getmarchinefz = params =>{return axios.get(`${base}api/app/getthreshold`,{params:params}).then(res =>res.data);}

// 设置阈值api/app/editfz
export const editfz = params => {return axios.post(`${base}api/app/editfz`,params).then(res =>res.data);}

// 获取车间菜单 api/app/getdepmenu
export const getdepmenu = params =>{return axios.get(`${base}api/app/getdepmenu`,{params:params}).then(res =>res.data);}

// 根据车间id 获取当前车间的信息api/app/getdepinfo
export const getdepinfo = params =>{return axios.get(`${base}api/app/getdepinfo`,{params:params}).then(res =>res.data);}

//换班 api/app/setbanci
export const setbanci = params => {return axios.post(`${base}api/app/setbanci`,params).then(res =>res.data);}

//获取机器实时状态 api/app/getmrealattr
export const getmrealattr = params =>{return axios.get(`${base}api/app/getmrealattr`,{params:params}).then(res =>res.data);}

// 获取api/app/getbomlist
export const getbomlist = params =>{return axios.get(`${base}api/app/getbomlist`,{params:params}).then(res =>res.data);}

//获取api/app/getsysinfo
export const getsysinfo = params =>{return axios.get(`${base}api/app/getsysinfo`,{params:params}).then(res =>res.data);}

//设置api/app/setsysinfo
export const setsysinfo = params => {return axios.post(`${base}api/app/setsysinfo`,params).then(res =>res.data);}


//设置停机原因  api/app/setstoptype
export const setstoptype = params => {return axios.post(`${base}api/app/setstoptype`,params).then(res =>res.data);}

//导入BOMid 和生产要素 api/app/imporbomandys"
export const imporbomandys = params => {return axios.post(`${base}api/app/imporbomandys`,params).then(res =>res.data);}

//imporbom 导入 BOM
export const imporbom = params => {return axios.post(`${base}api/app/imporbom`,params).then(res =>res.data);}

//获取生产日报表 api/app/getdayreport
export const getdayreport = params =>{return axios.get(`${base}api/app/getdayreport`,{params:params}).then(res =>res.data);}

//获取月报表数据 api/app/getmoutereport
export const getmoutereport = params =>{return axios.get(`${base}api/app/getmoutereport`,{params:params}).then(res =>res.data);}

//获取年报表数据 getyearreport

export const getyearreport = params =>{return axios.get(`${base}api/app/getyearreport`,{params:params}).then(res =>res.data);}

//app/editbom
export const editbom = params => {return axios.post(`${base}api/app/editbom`,params).then(res =>res.data);}

//getoptloglist
export const getoptloglist = params =>{return axios.get(`${base}api/app/getoptloglist`,{params:params}).then(res =>res.data);}

//getfaltloglist
export const getfaltloglist = params =>{return axios.get(`${base}api/app/getfaltloglist`,{params:params}).then(res =>res.data);}

export const getsuijilist = params =>{return axios.get(`${base}api/app/getsuijilist`,{params:params}).then(res =>res.data);}

//api/app/getshengchanrbblist
export const getshengchanrbblist = params =>{return axios.get(`${base}api/app/getshengchanrbblist`,{params:params}).then(res =>res.data);}

///api/app/gethomeinfo
export const gethomeinfo = params =>{return axios.get(`${base}api/app/gethomeinfo`,{params:params}).then(res =>res.data);}

//api/app/getjiadongllist
export const getjiadongllist = params =>{return axios.get(`${base}api/app/getjiadongllist`,{params:params}).then(res =>res.data);}

//api/app/getdepmachine
export const getdepmachine = params =>{return axios.get(`${base}api/app/getdepmachine`,{params:params}).then(res =>res.data);}

//getattrhistorylist
export const getattrhistorylist = params =>{return axios.get(`${base}api/app/getattrhistorylist`,{params:params}).then(res =>res.data);}

//
export const getivlist= params =>{return axios.get(`${base}api/app/getivlist`,{params:params}).then(res =>res.data);}

//保存异常消息通知手机 api/app/setbanci
export const editsmstel = params => {return axios.post(`${base}api/app/editsmstel`,params).then(res =>res.data);}

//获取报警人列表
export const getsmstellist= params =>{return axios.get(`${base}api/app/getsmstellist`,{params:params}).then(res =>res.data);}

//设置 异常停机的原因
export const editfault = params => {return axios.post(`${base}api/app/editfault`,params).then(res =>res.data);}

//getdlbycjtos 根据厂家id获取所有经销商到下拉框
export const getdlbycjtos= params =>{return axios.get(`${base}api/app/getdlbycjtos`,{params:params}).then(res =>res.data);}

//editmachine 编辑设备信息
export const editmachine = params => {return axios.post(`${base}api/app/editmachinenew`,params).then(res =>res.data);}

//getmachinebylist 获取设备列表
export const getmachinebylist= params =>{return axios.get(`${base}api/app/getmachinebylist`,{params:params}).then(res =>res.data);}

//释放设备信息
export const editdismachine = params => {return axios.post(`${base}api/app/editdismachine`,params).then(res =>res.data);}

//单个分配设备信息
export const editsfenpeimachine = params => {return axios.post(`${base}api/app/editsfenpeimachine`,params).then(res =>res.data);}

//api/app/getcustomlisttos 根据代理Id 获取所属的客户信息
export const getcustomlisttos= params =>{return axios.get(`${base}api/app/getcustomlisttos`,{params:params}).then(res =>res.data);}

//api/app/getmachinebyagentlist 获取代理相关的设备列表
export const getmachinebyagentlist= params =>{return axios.get(`${base}api/app/getmachinebyagentlist`,{params:params}).then(res =>res.data);}

//api/app/delsmstel 删除短信通知
export const delsmstel= params =>{return axios.get(`${base}api/app/delsmstel`,{params:params}).then(res =>res.data);}

//api/app/getmachinebywfp 获取厂商未分配数据
export const getmachinebywfp= params =>{return axios.get(`${base}api/app/getmachinebywfp`,{params:params}).then(res =>res.data);}

//api/app/editplfpmachine 批量分配设备
export const editplfpmachine = params => {return axios.post(`${base}api/app/editplfpmachine`,params).then(res =>res.data);}

// 获取客户菜单 api/app/getdepmenu
export const getcummenu = params =>{return axios.get(`${base}api/app/getcummenu`,{params:params}).then(res =>res.data);}

// 获取客户菜单 api/app/getmachinetechbyid
export const getmachinetechbyid = params =>{return axios.get(`${base}api/app/getmachinetechbyid`,{params:params}).then(res =>res.data);}

//设置当前工艺参数为标准值 app/setmachinespctotech
export const setmachinespctotech = params =>{return axios.get(`${base}api/app/setmachinespctotech`,{params:params}).then(res =>res.data);}

// 设置当前工艺参数标准值 app/setmachinetechbzz
export const setmachinetechbzz = params => {return axios.post(`${base}api/app/setmachinetechbzz`,params).then(res =>res.data);}

//设置当前工艺参数差值 api/app/setmachinetechcz
export const setmachinetechcz = params => {return axios.post(`${base}api/app/setmachinetechcz`,params).then(res =>res.data);}

//获取客户首页数据 app/home/cus
export const home_cus = params =>{return axios.get(`${base}api/app/home/cus`,{params:params}).then(res =>res.data);}

//获取MQTT tech 数据 app/getmqtttechdata
export const getmqtttechdata  = params =>{return axios.get(`${base}api/app/getmqtttechdata`,{params:params}).then(res =>res.data);}

//获取MQTT Spc 数据 app/getmqttspcdata
export const getmqttspcdata  = params =>{return axios.get(`${base}api/app/getmqttspcdata`,{params:params}).then(res =>res.data);}

//获取MQTT Realtime 数据 app/getmqttrealtimedata
export const getmqttrealtimedata  = params =>{return axios.get(`${base}api/app/getmqttrealtimedata`,{params:params}).then(res =>res.data);}

//获取mqtt wm 数据 getmqttwmdata
export const getmqttwmdata  = params =>{return axios.get(`${base}api/app/getmqttwmdata`,{params:params}).then(res =>res.data);}

//获取mqtt opmode 数据 getmqttopmodedata
export const getmqttopmodedata  = params =>{return axios.get(`${base}api/app/getmqttopmodedata`,{params:params}).then(res =>res.data);}

// 获取MQTT oplog getmqttoplogdata
export const getmqttoplogdata  = params =>{return axios.get(`${base}api/app/getmqttoplogdata`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/oplog
export const downoplogdata  = params =>{return axios.get(`${base}api/app/down/oplog`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/wm
export const downwmdata  = params =>{return axios.get(`${base}api/app/down/wm`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/opmode
export const downopmodedata  = params =>{return axios.get(`${base}api/app/down/opmode`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/realtime
export const downrealtimedata  = params =>{return axios.get(`${base}api/app/down/realtime`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/spc
export const downspcdata  = params =>{return axios.get(`${base}api/app/down/spc`,{params:params}).then(res =>res.data);}

//下载 oplog app/down/tech
export const downtechdata  = params =>{return axios.get(`${base}api/app/down/tech`,{params:params}).then(res =>res.data);}

//下载 tech word  app/down/techword
export const downtechword  = params =>{return axios.get(`${base}api/app/down/techword`,{params:params}).then(res =>res.data);}

//api/app/baoyang/list 获取保养列表
export const getbaoyanglist= params =>{return axios.get(`${base}api/app/baoyang/list`,{params:params}).then(res =>res.data);}

//  app/baoyang/edit  编辑保养
export const editbaoyang = params => {return axios.post(`${base}api/app/baoyang/edit`,params).then(res =>res.data);}

// app/baoyang/del  删除保养信息
export const delbaoyang = params => { return axios.get(`${base}api/app/baoyang/del`, {params:params}).then(res => res.data); };

//app/baoyang/sendsms  发送保养短信
export const sendbaoyangmsg = params => { return axios.get(`${base}api/app/baoyang/sendsms`, {params:params}).then(res => res.data); };

//app/sbgl/quxiantu  获取曲线图
export const getquxiantu = params => { return axios.get(`${base}api/app/sbgl/quxiantu`, {params:params}).then(res => res.data); };

//app/home/minfo  获取首页设备详情信息
export const getmachineinfotohome = params => { return axios.get(`${base}api/app/home/minfo`, {params:params}).then(res => res.data); };