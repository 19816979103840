<template>
   
    <el-row class="homebg">
      <el-row>
         <el-col :span="12" class="logoinfo">
          <img src="../assets/home/logo.png" /><span>中研云设备管理平台</span>
        </el-col>
        <el-col :span="12" class="userinfo">
          你好！<span>{{userinfo.UserName}}</span> | <span @click="modifypwd = true">修改密码</span> | <span @click="loginout">退出</span>
        </el-col>
        <el-col :span="4" class="homeinfo">
          <img v-if="userinfo.UserType != -1" @click="backhome" src="../assets/home/home.png" /><span v-if="userinfo.UserType != -1" @click="backhome" >首页</span>
        </el-col>
        <el-col :span="20" class="modelinfo">
          <ul class="models">
            <li v-for="(item,index) in model" :key="index" :class="item.route == selectmenu?'dd-this':''" @click="selectmodel(item);">{{item.text}}</li>
          </ul>
        </el-col>
      </el-row>
      <el-col :span="24" style="">
        <component v-bind:is="selectmenu"  ></component>
      </el-col>

      <el-dialog
        title=""
        :visible.sync="modifypwd"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <el-row style="    margin-top: -20px;">
        <el-col :span="24" class="stoptitle">
            修改密码
        </el-col>
        <el-col :span="24" style="margin-top:10px">
             <el-form :model="modifypwdMolde" :rules="modifypwdRules" ref="modifypwdForm" label-width="100px" >
                <el-form-item label="旧密码" prop="oldpwd">
                    <el-input  type="password" v-model="modifypwdMolde.oldpwd"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newpwd">
                    <el-input  type="password" v-model="modifypwdMolde.newpwd"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newpwd1">
                    <el-input type="password" v-model="modifypwdMolde.newpwd1"></el-input>
                </el-form-item>
                
                </el-form>
        </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
        <el-button @click="modifypwd = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('modifypwdForm')">确 定</el-button>
        </span>
    </el-dialog>
    </el-row>
 
  
</template>
<script>
export default {
  components: {
      "producesys":()=>import('../components/producesys'),
      "shebeigl":()=>import('../components/shebeigl'),
       
      "bomsys":()=>import('../components/bomsys'),
      "qualitysys":()=>import('../components/qualitysys'),
      "syslog":()=>import('../components/syslog'),
      "usersys":()=>import('../components/usersys')
  },
  data(){
    return{
      model:[{
        text:"系统管理",
        route:"usersys"
      },{
        text:"系统日志",
        route:"syslog"
      },{
        text:"品质管理",
        route:"qualitysys"
      },{
        text:"BOM管理",
        route:"bomsys"
      },{
        text:"设备数据",
        route:"shebeigl"
      },{
        text:"生产管理",
        route:"producesys"
      }],
      selectmenu:"producesys",
      modifypwd:false,
      modifypwdMolde:{
        oldpwd:'',
        newpwd:'',
        newpwd1:''
      },
      modifypwdRules:{
        oldpwd: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
                newpwd: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
                newpwd1: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
      
              },
      userinfo:{}
      
    }
  },
  created(){
    
    this.selectmenu = sessionStorage.getItem("selectmenu") ||'producesys';
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));

    if(this.userinfo.UserType == -1 || this.userinfo.UserType == 1){
      this.model = [{
        text:"系统管理",
        route:"usersys"
      },{
        text:"系统日志",
        route:"syslog"
      }];

      this.selectmenu = 'syslog'
    }

    if(this.userinfo.UserType == 2 ){
      this.model = [{
        text:"系统管理",
        route:"usersys"
      },{
        text:"系统日志",
        route:"syslog"
      },{
        text:"设备数据",
        route:"shebeigl"
      },{
        text:"生产管理",
        route:"producesys"
      }];
    }

   
  },
  methods:{
    selectmodel(selectitem){
      
     this.selectmenu = selectitem.route;
     sessionStorage.setItem("selectmenu",selectitem.route);
    },
    backhome(){
      console.log(this.userinfo)
      if(this.userinfo.UserType == -1){
        this.$router.push({ path: '/indexbyadmin' });
      }if(this.userinfo.UserType == 1){
        this.$router.push({ path: '/indexbycj' });
      }else if(this.userinfo.UserType == 2){
        this.$router.push({ path: '/indexbydls' });
      }
      else if(this.userinfo.UserType == 3){
        this.$router.push({ path: '/indexbykh' });
      }
    },
    submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                console.log('error submit!!');
                return false;
            }
        }); 
  },
  loginout(){
    let that = this;
    
    this.$confirm('确认要退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '正在退出登录...',
            });
            setTimeout(()=>{
              sessionStorage.clear();
              this.$router.push({path:'/login'});
            },1000)
            
          })
          .catch(() => {
            
          })
  }
  }
}
</script>

<style scoped>
  .homebg{
    background: url(../assets/home/headbg.png);
    height: 110px;
    width: 100%;
    background-size: cover;
    box-shadow: 0 0 10px #666;
  }

  .logoinfo{
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    padding-left: 20px;
    user-select: none;
  }

  .logoinfo img{
    vertical-align: middle;
  }

  .logoinfo span{
    font-size: 20px;
    vertical-align: middle;
    color: #fff;
    font-weight: bold;
  }

  .userinfo{
    height: 60px;
    line-height: 60px;
    text-align: right;
    padding-right: 20px;
    color: #fff;
    user-select: none;
  }

  .userinfo span{
    cursor: pointer;
  }

  .modelinfo{
    height: 50px;
    text-align: right;
  }

  .modelinfo .models {
    color: #fff;
    margin-right: 20px;
  }

  .modelinfo .models li{
    float: right;
    width: 100px;
    line-height: 50px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    transition: all 0.3s;
  }

  .modelinfo .models li + li{
    margin-left: 10px;
  }

  .modelinfo .models li:first-child{
    margin-left: 10px ;
  }

  .modelinfo .models li:hover,.modelinfo .models li.dd-this{
    background: #34869b;
  }
  .homeinfo{
    line-height: 50px;
    height: 50px;
    vertical-align: middle;
    color: #fff;
   font-size: 16px;
   padding-left: 20px;
   user-select: none;
  }

  .homeinfo span,.homeinfo img{
    vertical-align: middle;
    cursor: pointer;
  }

  
</style>