<template>
  <div>
    <div>
      <img class="titlebg" src="../assets/title.png" />
    </div>
    <div id="container">

    </div>
    <div class="datetimebox">
      <!-- <span @click="changetype(1)" :class="type == 1 ? 'this' : ''">统计模式</span>
      <span @click="changetype(2)" :class="type == 2 ? 'this' : ''">地图模式</span> -->
    </div>

    <div :class="['datetimebox', 'tuichu', type == 1 ? '' : 'xianse']">
      <span @click="tongjifanhuijxs" v-if="tongji_lookkh">返回</span>
      <span @click="loginoutbtn">退出</span>

    </div>
    <div v-if="tongji_lookkh" class="nowjxsdata">当前数据：{{selectkh}}</div>

     
    <el-row v-if="tongji_lookjxs"
      style="width: 100%;height: 100vh;background-color: #1f2e3d; color: #fff;position: absolute;z-index: 11;">
      <div class="shujumoshi">
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="售出设备运行情况">
              <div class="shujumoshicontent">
                <div id="shouchusbyxqk" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="客户">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">客户名称</th>
                      <th style="width: 110px;">总装机量(台)</th>
                      <th style="width: 110px;">本月购买量(台)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr @click="lookkhtongjidata(item)" v-for="(item, index) in allkehulistbyjxs">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="设备销售情况">
              <div class="shujumoshicontent">
                <div class="chanxiaoinfo">
                  <div>
                    <div>累计购买(台)</div>
                    <div>320</div>
                  </div>
                  <div>
                    <div>累计销售(台)</div>
                    <div>315</div>
                  </div>
                  <div>
                    <div>本月购买(台)</div>
                    <div>17</div>
                  </div>
                  <div>
                    <div>本月销售(台)</div>
                    <div>13</div>
                  </div>
                </div>
              </div>
              <div style="text-align: center;  ">
                <div id="nianduchanxiao" style="width: 100%;height: 300px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="年度销售走势图">
              <div class="shujumoshicontent">
                <div id="chanxiaozst" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="累计单品销售">
              <div class="shujumoshicontent">
                <div id="leijidpxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="累计销售前五客户">
              <div class="shujumoshicontent">
                <div id="xiaoshouqwjxs" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
      </div>
    </el-row>

    <el-row v-if="tongji_lookkh" style="width: 100%;height: 100vh;background-color: #1f2e3d; color: #fff;position: absolute;z-index: 11;">
      <div class="shujumoshi">
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="设备运行情况">
              <div class="shujumoshicontent">
                <div id="shouchusbyxqkbykh" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="当班产量">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">设备名称</th>
                      <th style="width: 110px;">产量</th>
                      <th style="width: 110px;">不良品数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in alldangbanchanlbykh">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                      <td> {{ item[2] }}</td>
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>

            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="良品率">
              <div class="shujumoshicontent">
                <div id="khliangpinl" style="width: 100%;height: 370px;"></div>
              </div>

            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="稼动率">
              <div class="shujumoshicontent">
                <div id="khjiadongl" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
        <div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="项目分析">
              <div class="shujumoshicontent">
                <div id="khxiangmufx" style="width: 100%;height: 370px;"></div>
              </div>
            </dv-border-box-11>
          </div>
          <div>
            <dv-border-box-11 :color="['#5198d4', '#154c6d']" title="当班运行时长">
              <div class="shujumoshicontent">
                <table border="0" class="pagedata" width="100%">
                  <thead>
                    <tr>
                      <th style="width: 50px;">#</th>
                      <th style="width: 40%;">设备名称</th>
                      <th style="width: 110px;">运行时长</th>
                       
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in alldangbanyxscbykh">
                      <td> <span>{{ index + 1 }}</span> </td>
                      <td> {{ item[0] }}</td>
                      <td> {{ item[1] }}</td>
                
                    </tr>
                  </tbody>

                </table>
                <div class="dapingpage" style="text-align: center;">
                  <el-pagination layout="prev, pager, next" :page-size="7" :total="7">
                  </el-pagination>
                </div>
              </div>
            </dv-border-box-11>
          </div>
        </div>
      </div>
    </el-row>

    <nav :class="['dd-nav', shownav ? 'show' : '']" @mouseleave="mouseleave" @mouseenter="mouseenter">
      <ul>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="生产管理" placement="left">
            <a>
              <span @click="selectmodel('producesys')" class="icon iconfont">&#xe62f;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="设备数据" placement="left">
            <a>
              <span @click="selectmodel('shebeigl')" class="icon iconfont">&#xe792;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="BOM管理" placement="left">
            <a>
              <span @click="selectmodel('bomsys')" class="icon iconfont">&#xe631;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="品质管理" placement="left">
            <a>
              <span @click="selectmodel('qualitysys')" class="icon iconfont">&#xe61c;</span>

            </a>
          </el-tooltip>
        </li>

        <li>
          <el-tooltip class="item" effect="dark" content="系统日志" placement="left">
            <a>
              <span @click="selectmodel('syslog')" class="icon iconfont">&#xe66a;</span>

            </a>
          </el-tooltip>
        </li>
        <li>
          <el-tooltip class="item" effect="dark" content="系统管理" placement="left">
            <a>
              <span @click="selectmodel('usersys')" class="icon iconfont">&#xe69b;</span>

            </a>
          </el-tooltip>
        </li>
      </ul>
    </nav>

    <div class="dd-mask" v-if="showddmask">
      <div>

        切换数据中，请稍后...
      </div>
    </div>

  </div>
</template>
<script>

import { gethomeinfo } from '../api/api';
import * as echarts from 'echarts';

export default {
  data() {
    return {
      mainbanheight: 0,
      value: new Date(),
      nowdate: 0,
      shownav: false,
      showtimer: null,
      nianyue: '',
      nowtime: '',
      nowweek: '',
      timexdata: [],
      homedata: {
        shebeiinfo: {},
        shebeifpfb: {}
      },
      timer: null,
      userinfo: null,
      map: null,
      timeKey: '1234',
      agentlist: [],
      type: 1,   //1 图表模式， 2 地图模式

      //以下是统计模式
        
      allkehulistbyjxs: [
          ['江苏启东塑料制品厂', 37, 21],
          ['东莞市世邦塑料制品厂', 35, 19],
          ['芜湖市协宏塑胶制品厂', 32, 19],
          ['中谷精密塑料制品公司', 29, 17],
          ['上海杜鹃塑料制品厂', 29, 15],
          ['世宏塑料制品(无锡)有限公司', 28, 15],
          ['惠州市塑料制品厂', 28, 14],
          
        ],
        tongji_lookjxs:true,
        tongji_lookkh:false,
        showddmask:false,

        alldangbanchanlbykh: [
        ['1号注塑机', 960, 21],
      ['2号注塑机', 936, 19],
      ['3号注塑机', 932, 19],
      ['4号注塑机', 929, 17],
      ['5号注塑机', 1029, 15],
      ['6号注塑机', 1018, 15],
      ['7号注塑机', 958, 14]
    ],
      alldangbanyxscbykh:[
        ['1号注塑机','6时23分' ],
      ['2号注塑机', '6时23分'],
      ['3号注塑机', '6时23分'],
      ['4号注塑机', '6时23分'],
      ['5号注塑机', '6时23分'],
      ['6号注塑机', '6时23分'],
      ['7号注塑机', '6时23分']
    ],

    selectkh:undefined, //当前选中客户信息
    
    };
  },
  created() {
    let that = this;
    this.currentTime();

    this.userinfo = JSON.parse(sessionStorage.getItem("user"));

    let nowdate = (new Date);
    let today = nowdate.getDate();
    this.today = today;

    this.gethomedata();

    if (this.timer) {
      clearInterval(this.timer);
    }
 
    setTimeout(() => {
      that.initecharts();
    }, 2000)

  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {
     
  },
  methods: {
     /* 退出系统*/
     loginoutbtn() {
      let that = this;

      this.$confirm('确认要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '正在退出登录...',
          });
          setTimeout(() => {
            sessionStorage.clear();
            that.$router.push({ path: '/login' });
          }, 1000)

        })
        .catch(() => {

        })
    },
    initechartsbykehu() {

//设备运行情况
let shouchusbyxqk = echarts.init(document.getElementById('shouchusbyxqkbykh'));

const optionshouchusbyxqk = {

  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '13%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    }
  },
  yAxis: {
    type: 'category',
    data: ['离线', '故障', '正常运行', '设备总量'],
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    }
  },
  series: [
    {
      name: '设备运行情况',
      type: 'bar',
      data: [1, 0, 15, 17]
    }

  ],
  legend: {
    data: ['设备运行情况'],
    textStyle: {
      color: '#fff'
    }
  },
}

shouchusbyxqk.setOption(optionshouchusbyxqk);

//良品率
let khliangpinl = echarts.init(document.getElementById('khliangpinl'));
const optionkhliangpinl = {
  color: ['red'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },

  legend: {
    data: ['良品率'],
    textStyle: {
      color: '#fff'
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['2024-03-16', '2024-03-17', '2024-03-18', '2024-03-19', '2024-03-20', '2024-03-21', '2024-03-22', '2024-03-23', '2024-03-24', '2024-03-25', '2024-03-26', '2024-03-27'],
      axisPointer: {
        type: 'shadow'
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '良品率(%)',
      min: 0,
      max: 100,

      axisLabel: {
        formatter: '{value} (%)'
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      }
    }
  ],
  series: [


    {
      name: '良品率',
      type: 'line',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' (%)';
        }
      },
      data: [
        97, 99, 97, 96, 97, 99, 99, 95, 93, 95, 94, 97
      ]
    }
  ]
};

khliangpinl.setOption(optionkhliangpinl);

//稼动率 khjiadongl
let khjiadongl = echarts.init(document.getElementById('khjiadongl'));
const optionkhjiadongl = {
  color: ['red'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },

  legend: {
    data: ['稼动率'],
    textStyle: {
      color: '#fff'
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['2024-03-16', '2024-03-17', '2024-03-18', '2024-03-19', '2024-03-20', '2024-03-21', '2024-03-22', '2024-03-23', '2024-03-24', '2024-03-25', '2024-03-26', '2024-03-27'],
      axisPointer: {
        type: 'shadow'
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '稼动率(%)',
      min: 0,
      max: 100,

      axisLabel: {
        formatter: '{value} (%)'
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      }
    }
  ],
  series: [
    {
      name: '稼动率',
      type: 'line',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' (%)';
        }
      },
      data: [
        90, 95, 97, 96, 97, 93, 90, 95, 93, 95, 94, 90
      ]
    }
  ]
};

khjiadongl.setOption(optionkhjiadongl);

//khxiangmufx 项目分析
let khxiangmufx = echarts.init(document.getElementById('khxiangmufx'));

const optionkhxiangmufx = {

  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '13%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    }
  },
  yAxis: {
    type: 'category',
    data: ['首件等待', '换单提醒', '超成型周期', '异常停机', '不良报警', '待停机数'],
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    },

  },
  series: [
    {
      name: '项目分析',
      type: 'bar',
      data: [1, 0, 0, 1, 1, 1]
    }

  ],
  legend: {
    data: ['项目分析'],
    textStyle: {
      color: '#fff'
    }
  },
}

khxiangmufx.setOption(optionkhxiangmufx);

},
    initecharts() {
      let chanxiaozst = echarts.init(document.getElementById('chanxiaozst'));
      const optionchanxiaozst = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度销量', '月度购买量'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度销量',
            min: 0,
            max: 50,
            
            axisLabel: {
              formatter: '{value} (台)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [

          {
            name: '月度销量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              13, 11, 9, 10, 15, 11, 8, 13, 10, 12, 9, 10
            ]
          },
          {
            name: '月度购买量',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
            15, 17, 6, 11, 9, 13, 8, 9, 10,9, 9, 15
            ]
          }
        ]
      };

      chanxiaozst.setOption(optionchanxiaozst);

      let nianduchanxiao = echarts.init(document.getElementById('nianduchanxiao'));
      const optionnianduchanxiao = {
        color:['red'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },

        legend: {
          data: ['月度购销比'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2023.3', '2023.4', '2023.5', '2023.6', '2023.7', '2023.8', '2023.9', '2023.10', '2023.11', '2023.12', '2024.1', '2024.2'],
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '月度购销比',
            min: 0,
            max: 110,
            
            axisLabel: {
              formatter: '{value} (%)'
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          }
        ],
        series: [

          
          {
            name: '月度购销比',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' (台)';
              }
            },
            data: [
              88, 92, 97, 105, 90, 103, 85, 82, 93, 95, 94, 90
            ]
          }
        ]
      };

      nianduchanxiao.setOption(optionnianduchanxiao);

      let xiaoshouqwjxs = echarts.init(document.getElementById('xiaoshouqwjxs'));
      const optionxiaoshouqwjxs = {
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        yAxis: {
          type: 'category',
          data: ['江苏启东塑料制品厂', '中谷精密塑料制品公司', '上海杜鹃塑料制品厂', '惠州市塑料制品厂', '沧州晨航塑料制品有限公司'],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        series: [
          {
            name: '累计销售前五客户',
            type: 'bar',
            data: [19, 21, 28, 33, 39]
          }

        ],
        legend: {
          data: ['累计销售前五客户'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      xiaoshouqwjxs.setOption(optionxiaoshouqwjxs);

      let leijidpxs = echarts.init(document.getElementById('leijidpxs'));
      const optionleijidpxs = {
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        yAxis: {
          type: 'category',
          data: ['VE III 400–8000', 'ZE 400–13600', 'JE 3600–33000', 'OE III 200–4000'],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        series: [
          {
            name: '累计单品销售',
            type: 'bar',
            data: [13, 27, 18, 16]
          }

        ],
        legend: {
          data: ['累计单品销售'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      leijidpxs.setOption(optionleijidpxs);

      let shouchusbyxqk = echarts.init(document.getElementById('shouchusbyxqk'));

      const optionshouchusbyxqk = {
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '13%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        yAxis: {
          type: 'category',
          data: ['离线', '故障', '正常运行', '售出设备总量'],
          axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
        },
        series: [
          {
            name: '售出设备情况',
            type: 'bar',
            data: [35, 27, 253, 315]
          }

        ],
        legend: {
          data: ['售出设备情况'],
          textStyle: {
            color: '#fff'
          }
        },
      }

      shouchusbyxqk.setOption(optionshouchusbyxqk);

    },
    gethomedata() {
      let that = this;
      gethomeinfo().then(res => {

        that.homedata = res.data;
        that.agentlist = res.data.agents;

        //   that.showyhfb();
       
      })
    },

    lookkhtongjidata(item){
      this.tongji_lookjxs = false;
      this.tongji_lookkh = true;
      this.showddmask = true;
      this.selectkh = item[0];
      let that = this;
      setTimeout(() => {
        that.initechartsbykehu();
        that.showddmask = false;
      }, 2000)
    },
    tongjifanhuijxs() {
     // this.tongji_lookjxs = true;
     // this.tongji_lookkh = false;
      this.showddmask = true;
     // let that = this;
      setTimeout(() => {
        // that.initecharts();
        // that.showddmask = false;
        window.location.reload();
      }, 2000)
    },

    mouseenter() {
      this.shownav = true;
      clearTimeout(this.showtimer);
    },
    mouseleave() {
      let that = this;
      this.showtimer = setTimeout(() => {
        that.shownav = false;
      }, 5000)
    },
    selectmodel(selectitem) {


      sessionStorage.setItem("selectmenu", selectitem);
      this.$router.push({ path: '/home' });

    },

    currentTime() {
      this.datetimer = setInterval(this.formatDate, 500);
    },
     
     
  },
  
};
</script>

<style scoped>
#container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.titlebg {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
}

.mainbox {
  height: calc(100vh - 1.2rem);

  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 1rem;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  user-select: none;
  pointer-events: none;
}

.mainbox .mianban {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  overflow: hidden;
  pointer-events: auto;
}

.mainbox .mianban.zhongjian {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.mainbox .mianban .box-title {
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.25rem;
  height: 0.25rem;
  border-left: 0.05rem solid #fff;
  padding-left: 0.1rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  user-select: none;
}

.box-body {
  color: #fff;
  padding: 0.2rem;
}

.box-body.dd-charts {
  padding: 0rem;
}

.box-body .box-body-label {
  display: inline-block;
  padding-right: 0.2rem;
  font-size: 0.18rem;
}

.box-body .box-body-label.shout>span {
  letter-spacing: 0.28rem;
}

.box-body .box-body-value {
  display: inline-block;
  width: calc(100% - 1.1rem);
}

.box-body .box-body-value>div {
  height: 0.12rem;
  background: linear-gradient(to right, #00bdf5, #006afe);
  transition: all 0.3s;
}

.box-body-value>div>span {
  margin-top: -0.25rem;
  float: right;
  font-size: 0.18rem;
}

.box-body>div+div {
  margin-top: 0.2rem;
}

.box-body {
  overflow-y: hidden;
}

.box-body .box-body-charts,
.box-body .box-body-info {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.datetimebox {
  position: absolute;
  margin-top: 0.1rem;
  color: #fff;
  line-height: 0.4rem;
  padding-left: 0.2rem;
  z-index: 9999;
  font-size: 16px;
}

.datetimebox.tuichu {
  right: 0.15rem;

}

.datetimebox>span {

  background: #008dd6e0;
  border: 1px solid #3dfffd;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
}

.datetimebox>span.this {
  background: #21d600ad;
}

.datetimebox.tuichu>span {
  background: transparent;
  border: none;
  color: #a4acb3;
}

.datetimebox.tuichu.xianse>span {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  color: #fff;
}

.agentlisttitle {
  width: 97%;
  margin-left: 1.5%;

}

.agentlisttitle>span {
  display: inline-block;
  width: 20%;
  text-align: center;
  border-top: 1px solid #00bdf5;
  border-bottom: 1px solid #00bdf5;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  margin-left: -1px;
  border-left: 1px solid #00bdf5;
}

.agentlisttitle>span:first-child {
  width: 40%;

}

.agentlisttitle>span:last-child {
  border-right: 1px solid #00bdf5;
  margin-left: -2px;
}

.agentlistbody.agentlisttitle>span {
  margin-top: -1px;
}

.agentlisttitle.head {
  background-color: #00bdf5;
  z-index: 999;

  position: relative;
}

.shujumoshi {
  height: calc(100% - 100px);
  margin-top: 100px;
}

.shujumoshi>div {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}

.shujumoshi>div:nth-child(2) {
  width: 40%;
}

.shujumoshi>div>div {
  height: calc(50vh - 50px);
  width: 100%;
}

.shujumoshi div.shujumoshicontent {
  padding: 60px 20px 20px 20px;
}

.shujumoshi div.chanxiaoinfo>div {
  display: inline-block;
  width: 25%;
  text-align: center;
  font-size: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.shujumoshi div.chanxiaoinfo>div>div:first-child {
  font-size: 18px;
  margin-bottom: 5px;

}

.shujumoshi div.chanxiaoinfo>div:first-child {
  background-color: #e062ae;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(2) {
  background-color: #fb7293;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(3) {
  background-color: #e690d1;
}

.shujumoshi div.chanxiaoinfo>div:nth-child(4) {
  background-color: #32c5e9;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.pagedata>thead>tr {
  background-color: rgb(0, 186, 255);

}

.pagedata>thead>tr>th {
  text-align: left;
}

.pagedata>thead>tr>th:first-child {
  text-align: center;
}

.pagedata tr>th,
.pagedata tr>td {
  height: 35px;
  line-height: 35px;
  border: none;
}

.pagedata tr>td:first-child {
  text-align: center;
}

.pagedata tr>td:first-child>span {
  background-color: rgb(0, 186, 255);
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
}

.pagedata tr>td {
  cursor: pointer;
}

table.pagedata,
table.pagedata th,
table.pagedata td {
  border: none !important;
  border-collapse: collapse !important;
}

.dd-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}

.dd-mask>div {
  text-align: center;
  margin-top: 48vh;
  font-size: 18px;
  color: #fff;

  letter-spacing: 4px;
}

.nowjxsdata{
  position: absolute;
    color: #a4acb3;
    font-size: 16px;
    right: 1.7rem;
    top: .2rem;
    z-index: 99;
}
</style>