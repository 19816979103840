<template>
  <div>
    <div>
      <img class="titlebg" src="../assets/title.png" />
    </div>
    <div id="container">

    </div>
    <div class="datetimebox">
      <div>{{ nowtime }}</div>
      <div>{{ nianyue }}</div>
    </div>
    <el-row :gutter="20" class="mainbox">
      <el-col :span="6">
        <div class="mianban" :style="{ 'height': mainbanheight + 'px' }">
          <div class="box-title">
            中研云设备管理平台
          </div>
          <!-- 设备总量 -->
          <div class="box-body">
            <div>
              <div class="box-body-label">设备总量</div>
              <div class="box-body-value">
                <div clas="">
                  <span>{{ homedata.shebeiinfo.all }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label">正常运行</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.run == 0 ? '0.3rem' : (homedata.shebeiinfo.run / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.run }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>故</span>障</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.guzhang == 0 ? '0.3rem' : (homedata.shebeiinfo.guzhang / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.guzhang }}台</span>
                </div>
              </div>
            </div>
            <div>
              <div class="box-body-label shout"><span>离</span>线</div>
              <div class="box-body-value">
                <div clas=""
                  :style="{ 'width': (homedata.shebeiinfo.lixian == 0 ? '0.3rem' : (homedata.shebeiinfo.lixian / homedata.shebeiinfo.all * 100) + '%') }">
                  <span>{{ homedata.shebeiinfo.lixian }}台</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mianban zhongjian" :style="{ 'height': mainbanheight + 'px' }">
          <div class="box-title">
            用户类型分布
          </div>
          <div class="box-body dd-charts">
            <div class="box-body-charts">
              <div id="myChart" :style="{ width: '100%', height: '1.9rem' }"></div>
            </div>
          </div>
        </div>

      </el-col>


    </el-row>

    <nav :class="['dd-nav', shownav ? 'show' : '']" @mouseleave="mouseleave" @mouseenter="mouseenter">
      <ul>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="生产管理" placement="left">
            <a>
              <span @click="selectmodel('producesys')" class="icon iconfont">&#xe62f;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 2 || userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="设备管理" placement="left">
            <a>
              <span @click="selectmodel('shebeigl')" class="icon iconfont">&#xe792;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="BOM管理" placement="left">
            <a>
              <span @click="selectmodel('bomsys')" class="icon iconfont">&#xe631;</span>

            </a>
          </el-tooltip>
        </li>
        <li v-if="userinfo.UserType == 3">
          <el-tooltip class="item" effect="dark" content="品质管理" placement="left">
            <a>
              <span @click="selectmodel('qualitysys')" class="icon iconfont">&#xe61c;</span>

            </a>
          </el-tooltip>
        </li>

        <li>
          <el-tooltip class="item" effect="dark" content="系统日志" placement="left">
            <a>
              <span @click="selectmodel('syslog')" class="icon iconfont">&#xe66a;</span>

            </a>
          </el-tooltip>
        </li>
        <li>
          <el-tooltip class="item" effect="dark" content="系统管理" placement="left">
            <a>
              <span @click="selectmodel('usersys')" class="icon iconfont">&#xe69b;</span>

            </a>
          </el-tooltip>
        </li>
      </ul>
    </nav>

  </div>
</template>
<script>

import { gethomeinfo } from '../api/api';

export default {
  data() {
    return {
      mainbanheight: 0,
      value: new Date(),
      nowdate: 0,
      shownav: false,
      showtimer: null,
      nianyue: '',
      nowtime: '',
      nowweek: '',
      timexdata: [],
      homedata: {
        shebeiinfo: {}
      },
      timer: null,
      userinfo: null,
      map: null
    };
  },
  created() {
    let that = this;
    this.currentTime();

    this.userinfo = JSON.parse(sessionStorage.getItem("user"));

    let nowdate = (new Date);
    let today = nowdate.getDate();
    this.today = today;

    this.gethomedata();

    if (this.timer) {
      clearInterval(this.timer);
    }

    // this.timer = setInterval(()=>{
    //     that.gethomedata();
    // },3e3);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    gethomedata() {
      let that = this;
      gethomeinfo().then(res => {

        that.homedata = res.data;
        that.timexdata = res.data.xzhou;

        that.showyhfb();
        that.showarea();
      })
    },

    showyhfb() {

      let myChart = this.$echarts.init(document.getElementById("myChart"));

      let option = {
        tooltip: {
          trigger: "item"
        },
        color: [
          "#8256e9",
          "#eb6f49",
          "#00d98b",
          "#fdb626",
          "#00e4ed",
          "#5e97e6",
          "#43a923"
        ],
        legend: {
          textStyle: {
            color: "#fff"
          },
          type: "scroll",
          align: "left",
          orient: "vertical",
          right: "5%"
        },

        series: [
          {
            name: "用户分布",
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },

            labelLine: {
              show: false
            },
            data: this.homedata.usertypelist
          }
        ]
      };

      // 绘制图表
      myChart.setOption(option);
    },

    showarea() {

      if (this.homedata.userlist.khuser.count > 0) {
        const dist = new BMapGL.DistrictLayer({
          name: this.homedata.userlist.khuser.list,
          fillColor: this.homedata.userlist.khuser.color,
          fillOpacity: 2
        });
        this.map.addDistrictLayer(dist);
      }

      if (this.homedata.userlist.jxsuser.count > 0) {
        const dist = new BMapGL.DistrictLayer({
          name: this.homedata.userlist.jxsuser.list,
          fillColor: this.homedata.userlist.jxsuser.color,
          fillOpacity: 2
        });
        this.map.addDistrictLayer(dist);
      }

      if (this.homedata.userlist.cjsuser.count > 0) {
        const dist = new BMapGL.DistrictLayer({
          name: this.homedata.userlist.cjsuser.list,
          fillColor: this.homedata.userlist.cjsuser.color,
          fillOpacity: 2
        });
        this.map.addDistrictLayer(dist);
      }

    },

    mouseenter() {
      this.shownav = true;
      clearTimeout(this.showtimer);
    },
    mouseleave() {
      let that = this;
      this.showtimer = setTimeout(() => {
        that.shownav = false;
      }, 5000)
    },
    selectmodel(selectitem) {


      sessionStorage.setItem("selectmenu", selectitem);
      this.$router.push({ path: '/home' });

    },

    currentTime() {
      this.datetimer = setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.nianyue = `${year}年${month < 10 ? '0' + month : month}月${day < 10 ? '0' + day : day}日`;
      this.nowtime = `${hour}:${minute}:${second}`;
      this.nowweek = `${weekArr[week]}`
    },
  },
  mounted() {
    let that = this;

    that.mainbanheight = (window.innerHeight - 160) / 3;

    that.map = new BMapGL.Map("container");
    that.map.setMapStyleV2({
      styleId: "95197ee848e8510f6cdc4fc27034dde9"
    });
    that.map.centerAndZoom(
      new BMapGL.Point(102.7977513, 32.28767026120888),
      5
    );
    that.map.enableScrollWheelZoom(true);

    that.map.addEventListener("click", function (e) {
      var point = new BMapGL.Point(e.latlng.lng, e.latlng.lat);
      var gc = new BMapGL.Geocoder();
      gc.getLocation(point, function (rs) { });
    });
  }
};
</script>

<style scoped>
#container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.titlebg {
  position: absolute;
  z-index: 999;
  width: 70%;
  margin-left: 15%;
}

.mainbox {
  height: calc(100vh - 1.2rem);

  position: fixed;
  width: 100%;
  z-index: 10;
  margin-top: 1rem;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  user-select: none;
  pointer-events: none;
}

.mainbox .mianban {
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  overflow: hidden;
  pointer-events: auto;
}

.mainbox .mianban.zhongjian {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.mainbox .mianban .box-title {
  color: #fff;
  font-size: 0.22rem;
  line-height: 0.25rem;
  height: 0.25rem;
  border-left: 0.05rem solid #fff;
  padding-left: 0.1rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  user-select: none;
}

.box-body {
  color: #fff;
  padding: 0.2rem;
}

.box-body.dd-charts {
  padding: 0rem;
}

.box-body .box-body-label {
  display: inline-block;
  padding-right: 0.2rem;
  font-size: 0.18rem;
}

.box-body .box-body-label.shout>span {
  letter-spacing: 0.28rem;
}

.box-body .box-body-value {
  display: inline-block;
  width: calc(100% - 1.1rem);
}

.box-body .box-body-value>div {
  height: 0.12rem;
  background: linear-gradient(to right, #00bdf5, #006afe);
  transition: all 0.3s;
}

.box-body-value>div>span {
  margin-top: -0.25rem;
  float: right;
  font-size: 0.18rem;
}

.box-body>div+div {
  margin-top: 0.2rem;
}

.box-body {
  overflow-y: hidden;
}

.box-body .box-body-charts,
.box-body .box-body-info {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.datetimebox {
  position: absolute;
  width: 2rem;
  right: 0.15rem;

  margin-top: 0.1rem;
  background: #008dd6e0;
  border: 1px solid #3dfffd;
  color: #fff;
  height: 0.8rem;
  line-height: 0.4rem;
  padding-left: 0.2rem;


  font-size: 0.26rem;
}
</style>