<template>
  <div class="loginBg">
    <div class="bgimg">
      <img src="../assets/login/title.png" />
      <div class="logbox">
         <div class="rightbox">
           <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px" class="demo-ruleForm login-container">
              <h3 class="title">用户登录</h3>
              <el-form-item prop="account">
                <el-input type="text" v-model="ruleForm2.account" auto-complete="off" @keyup.enter.native="handleSubmit2" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="checkPass">
                <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off" @keyup.enter.native="handleSubmit2" placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-checkbox v-model="checked" checked class="remember">记住密码</el-checkbox>
              <el-form-item style="width:100%;" class="loginbtn">
                <el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2" :loading="logining">登录</el-button>
                
              </el-form-item>
            </el-form>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { requestLogin } from '../api/api';
  
  export default {
    data() {
      return {
        logining: false,
        ruleForm2: {
          account: '',
          checkPass: ''
        },
        rules2: {
          account: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          checkPass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ]
        },
        checked: true
      };
    },
    methods: {
      handleReset2() {
        this.$refs.ruleForm2.resetFields();
      },
      handleSubmit2(ev) {
        var _this = this;
        this.$refs.ruleForm2.validate((valid) => {
          if (valid) {
         
            _this.logining = true;
            
            var loginParams = { loginname: this.ruleForm2.account, pwd: this.ruleForm2.checkPass };
            // sessionStorage.setItem('user', JSON.stringify(loginParams));
            // _this.$router.push({ path: '/index' });

            requestLogin(loginParams).then(res => {

              debugger;
              this.logining = false;
              //NProgress.done();
              let { msg, state, data } = res;
              if (state != 0) {
                this.$message({
                  message: msg,
                  type: 'error'
                });
              } else {
                
                sessionStorage.setItem('user', JSON.stringify(data));
                if(data.UserType == -1){
                  _this.$router.push({ path: '/home' });
                }
                else if(data.UserType == 1){
                  _this.$router.push({ path: '/indexbycj' });
                }else if(data.UserType == 2){
                  _this.$router.push({ path: '/indexbydls' });
                }else{
                  _this.$router.push({ path: '/Indexbykh' });
                }
                
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }

</script>


<style  scoped>

  .loginBg{
    background: #4bc0de;
        width: 100%;
    height: 100%;
  }
  .loginBg .bgimg{
    background-image: url(../assets/login/bg.png);
    width: 100%;
    height: 100%;
    background-size: cover;
    text-align: center;
  }

  .bgimg > img{
    margin-top: 100px;
  }

  .logbox{
     width: 865px;
     height: 450px;
     background-image: url(../assets/login/zzzz.png);
     background-size: cover;
 
    margin-top: 50px;
    left: 50%;
    position: absolute;
    margin-left: -433px;
  }

  .logbox .rightbox{
    width: 300px ;
    height: 430px;
    display: inline-block;
    
    margin-left: 332px;
    margin-top: 15px;
    text-align: left;
  }

  .logbox .rightbox h3{
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
    color: #444;
    margin-bottom: 50px;
  }

   .logbox .rightbox .demo-ruleForm> div,label{
     margin-bottom: 30px;
   }

   .logbox .loginbtn{
     margin-top: 20px;
   }
  
</style>