export default {

  getDate(year, month) {
    let date = new Date();
    year = year || date.getFullYear();
    month = month || date.getMonth() + 1;
    var d = new Date(year, month, 0);
    return d.getDate();
  },
  fomartDate(day) {
    return `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  },
  keepTwoDecimal(num) {
    var result = parseFloat(num);
    if (isNaN(result)) {

      return false;
    }
    result = Math.round(num * 100) / 100;
    return result;
  },
  formatTimeToHour(timeLong) {
    console.log(timeLong);
    let hour = Math.floor(timeLong / 3600) < 10 ? '0' + Math.floor(timeLong / 3600) : Math.floor(timeLong / 3600) ;
    let minute = Math.floor((timeLong - hour * 3600) / 60) < 10
      ? '0' + Math.floor((timeLong - hour * 3600) / 60)
      : Math.floor((timeLong - hour * 3600) / 60);
    let second = timeLong - hour * 3600 - minute * 60 < 10
      ? '0' + (timeLong - hour * 3600 - minute * 60)
      : timeLong - hour * 3600 - minute * 60;
    return (hour + '时' + minute + '分' + second +"秒")
  },
  

}