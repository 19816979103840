import Login from './views/Login.vue'
import NotFound from './views/404.vue'
import Index from './views/Index.vue'
import Indexbyadmin from './views/Indexbyadmin.vue'
import Indexbycj from './views/Indexbycj.vue'
import Indexbydls from './views/Indexbydls.vue'
import Indexbykh from './views/Indexbykh.vue'
import Home from './views/Home.vue'

let routes = [
  {
    path: '/login',
    component: Login,
    name: '',
    hidden: true
  },{
      path: '/404',
      component: NotFound,
      name: '',
      hidden: true
  },{
      path: '*',
      hidden: true,
      redirect: { path: '/login' }
  },{
      path: '/index',
      component: Index,
      hidden: true
       
  },{
      path: '/home',
      component: Home,
      hidden: true
       
  },{
    path: '/indexbyadmin',
      component: Indexbyadmin,
      hidden: true
  },{
    path: '/indexbycj',
      component: Indexbycj,
      hidden: true
  } ,{
    path: '/indexbydls',
      component: Indexbydls,
      hidden: true
  } ,{
    path: '/indexbykh',
      component: Indexbykh,
      hidden: true
  } 
];

export default routes;