import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'

import './css/common.css'
import './fonts/iconfont.css'

import VueRouter from 'vue-router'
import store from './vuex/store'
import Vuex from 'vuex'
 
import routes from './routes'

import './utils.js'

import Utils from './utils/common'
Vue.prototype.utils=Utils;
 

import scroll from 'vue-seamless-scroll'
import dataV from '@jiaminghi/data-view'
Vue.use(scroll)
Vue.use(dataV)

Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.config.productionTip = false

const router = new VueRouter({
  routes
})

import axios from 'axios';

axios.interceptors.request.use(config => {
  // 表示在配置中的设置头消息的字段Authorization为从本地获取的token值
  let user = JSON.parse(sessionStorage.getItem('user'));
  if(user){
    config.headers.userid = user.Id;
    config.headers.username = user.LoginName;
  }
  return config
})
 
router.beforeEach((to, from, next) => {
  //NProgress.start();
  if (to.path == '/login') {
    sessionStorage.removeItem('user');
  }
  let user = JSON.parse(sessionStorage.getItem('user'));
  if (!user && to.path != '/login') {
    next({ path: '/login' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
